import styled from 'styled-components';
import { useGlobalContext } from '../../../context/global';
import { useTextContext } from '../../../context/text/TextContext';
import { scenarioData, scenarioDataAvailable } from '../../../data/scenarioData';
import BasicButton from '../../Buttons/BasicButton';
import BasicFrame from '../../Frames/BasicFrame';
import { Heading, Paragraph } from '../../Typography';
import Container from '../components/Container';

const StartScreen = () => {

	const {LoadScene, setGameState, setTutorialStart} = useGlobalContext();
	const {t} = useTextContext();

	const startGame = () => {
		setGameState && setGameState(2);	
	};

	const startTutorial = () => {
		LoadScene && LoadScene('tutorial');
		setGameState && setGameState(0);
		setTutorialStart && setTutorialStart(true);	
	};

	return (  
		<Container>					
			<StyledFrame>	
				<FrameContent>	
					<div>
						<Heading>Game</Heading>
						<br/>				
						<Paragraph>
							{t('start1')}
						</Paragraph>				
						<br/>
						<Paragraph>{t('start2')}</Paragraph>
						<Paragraph><b>{scenarioDataAvailable() && scenarioData ? scenarioData[0].name : '[rol]'}</b></Paragraph>
					</div>			
				</FrameContent>					
			</StyledFrame>
			<ButtonRow>		
				<BasicButton style={{width: '25%'}} onClick={startGame}>Start scenario</BasicButton>
				<BasicButton style={{width: '25%'}} onClick={startTutorial}>Tutorial</BasicButton>
			</ButtonRow>
		</Container>
	);
};

// styled components

const StyledFrame = styled(BasicFrame)`
	position: relative;
	box-sizing: border-box;
	width: 100%;
	text-align: center;
	min-height: 0;

	display: flex;
`;

const FrameContent = styled.div`
	position: relative;
	overflow-y: auto;
	max-height: 100%;
`;

const ButtonRow = styled.div`	
	
	display: flex;
	width: 100%;
	justify-content: center;
	& > * {
		margin: 0% 5%;
	}

`;


export default StartScreen;