import { DefaultType, PartialItem } from '@directus/sdk';
import { directus, directus_url, PannellumHotspotData, PannellumSceneData, PannellumScenePannellumHotspotsData, ScenarioData, TaskData } from '../../services/directus';
import { addScene, addHotSpot} from 'react-pannellum';
import PannellumConfig from './PannellumConfig';
import { DecisionData, Option } from '../../data/decisions';
import { scenarioData, scenarioDataAvailable } from '../../data/scenarioData';
import { textDataRecord } from '../../context/text/types';

// types

export type SceneListEntry = {
	sceneData: PannellumSceneData;
	additionalData: {
		path: string;
	};
	decisionData?: DecisionData;
}

export type SceneList = {
	[Key in string]?: SceneListEntry;
}

export const scenelist: SceneList = {};

let scenesData: undefined | PartialItem<PannellumSceneData>[] | null;
let hotspotData: undefined | PartialItem<PannellumHotspotData>[] | null;
let relationData: undefined | PartialItem<PannellumScenePannellumHotspotsData>[] | null;
let filesData: undefined | PartialItem<DefaultType>[] | null;
const textsObject: textDataRecord = {};
const language = 'nl-NL';

export {filesData};

const initializeData = async () => {
	const scenarioNumber = parseInt(window.location.pathname.substring(10));

	// if(scenarioDataAvailable()){
	// 	await directus.items('pannellum_scenes').readMany(scenarioNumber ? {	
	// 		filter: {
	// 			scenario: {
	// 				_eq: scenarioNumber,
	// 			},
	// 		}} : {}).then((a) => {scenesData = a.data;});
	// }
	// else{
	// 	await directus.items('pannellum_scenes').readMany().then((a) => {scenesData = a.data;});
	// }
	await directus.items('texts_translations').readMany({
		filter:{languages_code:{_eq: language}},
		limit: -1
	}).then((a) => {
		a.data && a.data.map((text) => {
			text.texts_key && text.text ? textsObject[text.texts_key] = text.text : '';
		});
	});
	await directus.items('pannellum_scenes').readMany({limit: -1}).then((a) => {scenesData = a.data;});
	await directus.items('pannellum_hotspots').readMany({limit: -1}).then((a) => hotspotData = a.data);
	await directus.items('pannellum_scenes_pannellum_hotspots').readMany({limit: -1}).then((a) => relationData = a.data);
	await directus.files.readMany({limit: -1}).then((a) => filesData = a.data);
	return;
};
export { scenesData, hotspotData };

// methods


const createScenelist = async (hotspotFunction: (type: string | undefined, decision: DecisionData | undefined, pannellum: string | undefined, dialogue: string | undefined, overlay: number | undefined) => void): Promise<SceneList> => {

	const promises = scenesData?.map(async (scene) => {
		
		let path = '';

		if(filesData){
			const file = filesData.find(file => {
				return file.id === scene.render;
			});

			const decision_options: Option[] = [];
			if(scene.decision_group_toggle){
				scene.hotspots?.map((decision_hotspot) => {
					if(hotspotData && relationData){
						const hotspot_id = relationData.find((entry) => {return entry.id === decision_hotspot;})?.pannellum_hotspots_id;
						const hotspot = hotspotData.find((entry) => {return entry.id === hotspot_id;});
						decision_options.push({option: hotspot?.option_text, feedback: hotspot?.feedback});
					}
				});
			}
			path = `${directus_url}assets/${file?.filename_disk}${`?${window.location.href}`}`;
			const customConfig = scene.customConfig ? JSON.parse(scene.customConfig) : {};
			const sceneConfig = {sceneId: scene.scene_id, ...PannellumConfig, ...customConfig, imageSource : path, pitch: scene.pitch, yaw: scene.yaw};
	
			addScene(scene.scene_id, sceneConfig, () => addHotspots({hotspotFunction, scene}));
	
			scene.scene_id ? scenelist[scene.scene_id?.toString()] = {
				sceneData: {
					starting_dialogue: scene.starting_dialogue ? scene.starting_dialogue : '',
					hotspots: scene.hotspots,
					scene_id: scene.scene_id,
					key: scene.key ? scene.key : 'null',
					render: scene.render ? scene.render : 'null',
					customConfig: scene.customConfig ? scene.customConfig : 'null',
					pitch: scene.pitch ? scene.pitch : 0,
					yaw: scene.yaw ? scene.yaw : 0,
					scenario: scene.scenario ? scene.scenario : 0,
					decision_group_toggle: scene.decision_group_toggle ? scene.decision_group_toggle : false,
					description: scene.description ? scene.description : 'no description',
					correct_option: scene.correct_option ? scene.correct_option : 0,
					task: scene.task ? scene.task : '',
					porto_toggle: scene.porto_toggle ? scene.porto_toggle : false,
					porto_dialogue: scene.porto_dialogue ? scene.porto_dialogue : '',
					sound: scene.sound ? scene.sound : '',
					background_sound: scene.background_sound ? scene.background_sound : ''
				},
				additionalData: {
					path: path,
				},
				decisionData: {
					description: scene.description ? scene.description : 'no description',
					options: decision_options,
					correct_option: scene.correct_option ? scene.correct_option : 0,
					chosen_option: null, //nader te bepalen hoe we dit invullen
					task: scene.task ? scene.task : '',
					adc: false
				} 
			} : '';
		}
	});

	if(promises){
		await Promise.all<any>(promises);
	}
	return scenelist;
	// return sceneList;
};

		
const addHotspots = (props: {hotspotFunction: (type: string | undefined, decision: DecisionData | undefined, pannellum: string | undefined, dialogue: string | undefined, overlay: number | undefined) => void, scene : Partial<PannellumSceneData>}) => {
	props.scene.hotspots?.map((hs) => {
		if(relationData){

			const relation = relationData.find(relation => {
				return relation.id == hs;
			});

			const hotspot = hotspotData?.find(hotspot => {
				return hotspot.id == relation?.pannellum_hotspots_id;
			});	

			const obj : any = {...hotspot};
			obj.createTooltipFunc = createCustomHotspot;
			obj.createTooltipArgs = {
				'text': textsObject[obj.option_text] ? textsObject[obj.option_text] : '[geen tekst gevonden in het CMS]',
				'decision': props.scene.decision_group_toggle
			};
			obj.cssClass = props.scene.decision_group_toggle  ? 'decision': hotspot?.type;
			obj.clickHandlerFunc = () => {
				const decisionData = scenelist[props.scene.scene_id? props.scene.scene_id : 0]?.decisionData;
				if(decisionData && hotspot?.id) decisionData.chosen_option = parseInt(hotspot.id.toString());
				props.hotspotFunction(
					hotspot?.type,
					scenelist[props.scene.scene_id? props.scene.scene_id : 0]?.decisionData, 
					hotspot?.target_scene, 
					hotspot?.target_dialogue, 
					hotspot?.target_overlay 
				);
			};
			addHotSpot(obj, props.scene.scene_id);
		}
	});
};

const createCustomHotspot = (hotspot: any, args: any) => {

	const div = document.createElement('div');
	const wrapper = document.createElement('div');
	const text = document.createElement('div');
	const selection = document.createElement('div');
	wrapper.appendChild(text);
	hotspot.appendChild(div);


	if(args.decision == true){

		wrapper.classList.add('decision-wrapper');

		div.appendChild(wrapper);

		const decisionButton = document.createElement('button');
		decisionButton.innerHTML = args.text;

		const backdrop = document.createElement('div');
		backdrop.classList.add('decision-button-backdrop');

		decisionButton.appendChild(backdrop);

		wrapper.appendChild(decisionButton);

		for(let i = 0; i < 4; i++){
			const ndiv = document.createElement('div');
			selection.appendChild(ndiv);
		}

		selection.classList.add('decision-selection');

		decisionButton.appendChild(selection);


	}

	
};
// let pannellumConfig = {};

// const createConfig = async (collection:OneItem<PannellumConfigData>) : Promise<PannellumConfig> => {
// 	const config = collection && collection.config && {...JSON.parse(collection.config)};
// 	pannellumConfig = config;
// 	return config;
// };

export {createScenelist, initializeData};

export type PannellumConfig = string;
