import styled from 'styled-components';
import { useGlobalContext } from '../../../context/global';
import BasicButton from '../../Buttons/BasicButton';
import BasicFrame from '../../Frames/BasicFrame';
import { Heading, Paragraph } from '../../Typography';
import Container from '../components/Container';

const GameoverScreen = () => {

	const RestartGame = () => {
		location.reload();		};

	return (  
		<Container>					
			<StyledFrame>						
				{/* <Heading>Vandaag neem jij de rol aan van {scenarioData? scenarioData[0].name : '[rol]'}</Heading> */}
				<Heading>Game over</Heading>
				<br/>				
				<Paragraph>
				Je hebt meerdere keren te laat gereageerd. Tijdens een grootschalig incident is het belangrijk snel te kunnen schakelen en beslissingen te nemen. Als je dit niet doet kan er snel chaos ontstaan.				
				</Paragraph>				
				<br/>
				<Paragraph>Probeer het nog een keer.</Paragraph>
			</StyledFrame>
			<ButtonRow>		
				<BasicButton style={{width: '25%'}} onClick={RestartGame}>Opnieuw spelen</BasicButton>
			</ButtonRow>
		</Container>
	);
};

// styled components

const StyledFrame = styled(BasicFrame)`
	box-sizing: border-box;
	width: 100%;
	text-align: center;
	//////
`;

const ButtonRow = styled.div`	
	
	display: flex;
	width: 100%;
	justify-content: center;
	& > * {
		margin: 0% 5%;
	}

`;


export default GameoverScreen;