import { ID } from '@directus/sdk';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
import { AvatarData } from '../../services/directus';
import { Heading } from '../Typography';
import { avatarList } from './DialogueData';
 
const Avatar = (props:{situation: number; avatarId : ID | null | undefined}) => {
	
	const [avatar, setAvatar] = useState<AvatarData | undefined>();

	useEffect(() => {
		if(props.avatarId){
			setAvatar(avatarList[props.avatarId]);
		}
		else{
			setAvatar(undefined);
		}
	}, [props.avatarId]);

	const imageContainer = useRef<HTMLDivElement | null>();

	return (
		<>
			{ avatar &&
				<Container situation={props.situation}>
					<ImageContainer ref={imageContainer}>
						<Image situation={props.situation} url={avatar.avatar_image}/>
					</ImageContainer>
					<Nameplate>
						<Heading>{avatar.name}</Heading>
					</Nameplate>
				</Container>
			}
		</>

	);
};

// styled components

const Container = styled.div<{situation: number}>`
	position: relative;
	/* margin-bottom: 15px; */
	width: 30%;
	/* height: 100%;
	margin-top: auto; */
	/* max-height: 50vh; */
	min-height: 0;
	margin-right: auto;
	z-index: 2;

	@media (max-width: ${p => p.theme.responsive.media.lg}){
			/* transform: translateY(100%); */
			height: 0;
	}

`;

const Image = styled.div<{situation: number, url: string}>`
	/* height: 100%; */
	background-image: url(${p => p.url});
	background-repeat: no-repeat;
	background-size: contain;
	background-position: left bottom;
	height: 100%;

	transform-origin: center;

	/* transition: transform 1s; */

	${ p => (p.situation === 3 || p.situation === 4) && css`
		@media (max-width: ${p => p.theme.responsive.media.lg}){
			/* transform: translateY(100%); */
			height:0;
		}
	`
}
`;

const ImageContainer = styled.div`
	height: 100%;
	width: 100%;
	@media (min-width: ${p => p.theme.responsive.media.lg}){
		min-height:150px;
	}
	
	overflow: hidden;
`;

const Nameplate = styled.div`

	position: absolute;
	left: 50px;bottom:0;
	width: fit-content;

	background: white;
	color: #164395;
	/* padding: 0 2vw; */

	display: flex;

	& > div {
		margin: 5px 10px;
		white-space: nowrap;
	}

	@media (max-width: ${p => p.theme.responsive.media.lg}){
		transform: translate(0, 80%);
    }

	@media (min-width: ${p => p.theme.responsive.media.lg}){
		transform: translate(0, 50%);
    }


`;
 
export default Avatar;
