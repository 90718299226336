import { useCallback, useEffect, useRef, useState } from 'react';
import { useGlobalContext } from '../../context/global';

const Tutorial = () => {

	const {activePannellumScene, LoadScene, setGameState} =useGlobalContext();


	const sceneIdCheckMove = 'tutorial';
	const sceneIdCheckMoveFollowUp = 'tutorial1';
	const tutorialEndScene = 'tutorialend';

	const upToDateScene = useRef(activePannellumScene);

	const lookedAround = (e: any) => {
		if(upToDateScene.current != sceneIdCheckMove) return;
		LoadScene && LoadScene(sceneIdCheckMoveFollowUp);
	};

	useEffect(() => {	
		upToDateScene.current = activePannellumScene;
		const el = document.getElementById('-1');

		switch(activePannellumScene){
		case sceneIdCheckMove :	
			// Look around	
			if(!el) return;
			el.addEventListener('mouseup', lookedAround, {once: true});
			el.addEventListener('touchmove', lookedAround, {once: true});
			break;
		case tutorialEndScene: 
			setGameState && setGameState(2);
			break;
		}
	}, [activePannellumScene]);
	
	return (
		<>
		</>
	);
};

export default Tutorial;


