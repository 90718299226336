import { ReactNode } from 'react';
import styled from 'styled-components';
import { ButtonText } from '../Typography';
import Button from './Button';

 
const BasicButton = (props: {disabled?, style?, children: ReactNode, className?: string, onClick?: () => void}) => {
	return (  
		<StyledButton style={props.style} disabled={props.disabled} onClick={() => props.onClick && props.onClick()}className={props.className}>
			<div/>
			<Cover/>
			<Cover/>
			<StyledButtonText>{props.children}</StyledButtonText>
		</StyledButton>
	);
};

// styled components

const StyledButton = styled(Button)`

	color: ${p => p.theme.maincolors.GGBblue};
	text-transform: uppercase;
	background: white;
    font-family: 'FacitBold';
	position: relative;

	@media (max-width: ${p => p.theme.responsive.media.lg}){
		padding: ${p => p.theme.responsive.padding.sm}px ${p => p.theme.responsive.padding.sm}px;
		padding: 1.5vw 2vw;
    }

	@media (min-width: ${p => p.theme.responsive.media.lg}){
		padding: ${p => p.theme.responsive.padding.lg}px ${p => p.theme.responsive.padding.lg}px;
		padding: 1.5vw 2vw;
    }

	& > :nth-child(3) {
		/* transition: width 1s; */
	}

		& > :nth-child(1) {
			content:'';
			position: absolute;

			@media (max-width: ${p => p.theme.responsive.media.lg}){
				left: 5px; top: 5px; right: 5px; bottom: 5px;
				border: 2px solid ${p => p.theme.maincolors.GGBblue};

			}
			@media (min-width: ${p => p.theme.responsive.media.lg}){
				left: 5px; top: 5px; right: 5px; bottom: 5px;
				border: 4px solid ${p => p.theme.maincolors.GGBblue};
			}
		}

	
	&:active {
		background: ${p => p.theme.maincolors.GGBblue};
		color: white;
		& > :nth-child(2){
			background: ${p => p.theme.maincolors.GGBblue};
		}
		& > :nth-child(3){
			background: ${p => p.theme.maincolors.GGBblue};

		}
	}


	& > :nth-child(2) {
		position: absolute;
		left:0;
		top:30%;
		width: 100%;
		height: 40%;
		background: white;

	}

	& > :nth-child(3) {
		position: absolute;
		left:10%;
		top:0;

		width: 80%;
		height: 100%;
		background: white;

		
		/* @media (max-width: ${p => p.theme.responsive.media.lg}){
			left: 12px;
			right: 12px;
		}
		@media (min-width: ${p => p.theme.responsive.media.lg}){
			left: 30px;
			right: 30px;
		} */

	}

	&:hover{
		& > :nth-child(3) {
			transform: scaleX(0);
		}
	}

	&:disabled {
		filter: grayscale(100%);
		pointer-events: none;
		background: grey;
		& > :nth-child(2){
			background: grey;
		}
		& > :nth-child(3){
			background: grey;
		}
	}

	&:focus{
		//text-decoration: underline;
		& > :nth-child(3) {
			transform: scaleX(0);
		}
	}
	
`;

const StyledButtonText = styled(ButtonText)`
	position: absolute;
	left:0;top:0;right:0;bottom:0;
`;

const Cover = styled.div`
	transition: transform .5s;
	/* transform-origin: center; */
`;

 
export default BasicButton;
