import { ID, PartialItem } from '@directus/sdk';
import { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
import { useGlobalContext } from '../../../context/global';
import { scenarioData } from '../../../data/scenarioData';
import { directus, ScenariosFilesData } from '../../../services/directus';
import { theme } from '../../../style';
import Button from '../../Buttons/Button';
import CloseButton from '../../Buttons/CloseButton';
import BasicFrame from '../../Frames/BasicFrame';
import { Heading } from '../../Typography';
import {default as InnerContainer} from '../components/Container';


const Taakkaart = () => {

	const { showTaskCard, setShowTaskCard, getFile, showCards, setShowCards } = useGlobalContext();
	const swapIcon = getFile && getFile('af2c8bb7-6c75-4523-a5be-9518b4e7a598');
	const [taskCardArray, setTaskCardArray] = useState<string[]>([]);

	const getTaskcardsnData = async () => {
		let taskCardsData: undefined | PartialItem<ScenariosFilesData>[] | null;

		const scenarioNumber = parseInt(window.location.pathname.substring(10));

		await directus.items('scenarios_directus_files').readMany(scenarioNumber ? {	
			filter: {
				scenarios_id: {
					_eq: scenarioNumber,
				},
			},
			sort: ['order']
		} : {}).then((a) => {
			taskCardsData = a.data;
			const array = showCards ? [...showCards] : [];
			array[2] = false;
			a.data?.length === 0 && setShowCards && setShowCards([...array]);
		});
			
		await taskCardsData?.map((taskCard) => {
			taskCard.directus_files_id && taskCardArray.push(taskCard.directus_files_id);
		});
		return;
	};

	useEffect(() => {
		getTaskcardsnData();
	},[]);

	const [activeSide, setActiveSide] = useState<number>(0);

	return (  
		<>
			{showTaskCard && 
				<Container> 
					<Rows>
						{/* <StyledFrame>
									<Heading>
										Taakkaart
									</Heading>						
								</StyledFrame> */}
						<Swap onClick={() => setActiveSide(activeSide-1)} usable={taskCardArray[activeSide-1]} left={true} url={swapIcon}><StyledButtonFrame/></Swap>			
			
						<ImageBox url={getFile && getFile(taskCardArray[activeSide])}>
		
						</ImageBox>
						<Swap onClick={() => setActiveSide(activeSide+1)} usable={taskCardArray[activeSide+1]}  url={swapIcon}><StyledButtonFrame/></Swap>	
					</Rows>
					<CloseWrapper>
						<CloseButton onClick={() => setShowTaskCard && setShowTaskCard(false)}></CloseButton>
					</CloseWrapper>
				</Container>}
		</>
	);
};

// styled components

const Container = styled.div`

    position: absolute;
    left:0;top:0;
    box-sizing: border-box;

    z-index: 3;

    display: flex;

    background: rgb(0,0,0,.75);

	height: 100%;
	width: 100%;

    @media (max-width: ${p => p.theme.responsive.media.lg}){
		padding: ${p => p.theme.responsive.padding.sm}px;
    }

	@media (min-width: ${p => p.theme.responsive.media.lg}){
		padding: ${p => p.theme.responsive.padding.lg}px;
    }
`;


const CloseWrapper = styled.div`
	position: absolute;

	@media (max-width: ${p => p.theme.responsive.media.lg}){
		right: ${p => p.theme.responsive.padding.sm}px;
    }

	@media (min-width: ${p => p.theme.responsive.media.lg}){
		right: ${p => p.theme.responsive.padding.lg}px;
    }
`;

const Swap = styled(Button)<{url: string | undefined, left: boolean, unusable: boolean}>`
	width: 50px;
	height: 50px;

    background-image: url(${p => p.url});
    background-size: 50% 50%;
    background-repeat: no-repeat;
    background-position: center;
	background-color: white;
	visibility: ${p => p.usable ? '' : 'hidden'};

	//transform: translate(130%);

	${p => p.left && css`
	transform: rotate(180deg);
	& > :nth-child(1){
		transform: rotate(180deg);
	}
	`}
	
	cursor: pointer;

    &:hover{
        background-size: 60% 60%;
    }
`;

const StyledButtonFrame = styled(BasicFrame)`
	background: none;
	&:after{
		border: 1px solid ${p => p.theme.maincolors.GGBblue};    
        left: 3px; top: 3px; right: 3px; bottom: 3px;
	}
	width: 100%;
	height: 100%;
	padding:0;
`;

const Columns = styled.div`
    display: flex;
	flex-direction: row;
	justify-content: flex-end;
	width:100%;
	height: 100%;
	> * + * {
		margin-left: 15px;
	}  
`;

const Rows = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
`;

const StyledFrame = styled(BasicFrame)`
	& > div {
		margin : 0;
		text-align: center;
	}
`;

const ImageBox = styled.div<{url: string | undefined}>`
    background-image: url(${p => p.url});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	flex-grow:1;
	background-color: white;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0px 10px;
`;


export default Taakkaart;