import { createContext, useContext, useEffect, useRef, useState  } from 'react';
import { contextProps, globalContextProps } from './types';
import {loadScene} from 'react-pannellum';
import { directus, directus_url, FileData, use_cms} from '../../services/directus';
import { scenarioData } from '../../data/scenarioData';

const GlobalContext = createContext<Partial<globalContextProps>>({});

const GlobalContextProvider = (props: contextProps) => {

	//#region functions

	const onSetDisplayConsole = (val:any) => {
		setDisplayConsole(val);
	};

	const onToggleDisplayConsole = () => {
		setDisplayConsole(e => !e);
	};

	const LoadScene = (val:string) =>{
		loadScene(val);
		setActivePannellumScene(val);
	};

	//#region lives functionality

	const takeLife = () => {
		setLives((a) => {
			const Lives = [...a];	
			const found = Lives.findIndex(element => element == true);
			Lives[found] = false; 		
			return Lives;
		});
	};

	//#endregion

	//#region timer

	const [startTime, setStartTime] = useState<number>();
	const [time, setTime] = useState<number>(0);
	const interval = useRef<any | null>(null);
	const [callback2, setCallback] = useState<() => void>();
	const[countStartedTimers, setCountStartedTimers] = useState<number>(0);

	const startTimer = (seconds: number, callback? : () => void) => {
		if(timerToggle){
			setCountStartedTimers(countStartedTimers +1);
			setShowCards([showCards[0], true, showCards[2], showCards[3]]);
			setTime(seconds/1000);
			setStartTime(seconds/1000);
			setCallback(() => callback);
		
			interval.current = setInterval(() => {
				setTime(e => e - 1);
			}, 1000);
		}
	};

	const timerEnded = () => {
		if(startTime){
			takeLife();
			clearTimeout(interval.current);
			callback2 && callback2();
			setTimeout(() => {
				setShowCards([showCards[0], false, showCards[2], showCards[3]]);
			},1500);
		}
	};

	const endTimeEarly = () => {
		setTime(0);
	};

	const stopTimer = () => {
		clearTimeout(interval.current);
		setShowCards([showCards[0], false, showCards[2], showCards[3]]);
	};

	//#endregion

	//#region Files data

	const [files, setFiles] = useState<FileData | undefined>();

	const getFilesData = async () => {
		const loadCheckId = addExternalLoadCheck();

		await directus.files.readMany({limit: -1}).then((a) => {
			const fileData : FileData = {};
			a.data && a.data.map((file) => {
				fileData[file.id] = file;
			});
			setFiles(fileData);
		});

		loadCheckId !== undefined && completeExternalLoadCheck(loadCheckId);
	};

	const getFile = (id: string) => {
		if(!files) return undefined;
		if(use_cms){
			return files[id] ? `${directus_url}assets/${files[id].filename_disk}&${files[id].modified_on}?access_token=j1bberj3bber` : 'file not found';
		} else {
			return files[id] ? require(`./files/${files[id].filename_disk}`) : 'file not found';
		}
	};
	// External load functions

	const [externalLoadChecks, setExternalLoadChecks] = useState<boolean[]>([]);
	const startingTime = Date.now();
	let delayedCheck: undefined | ReturnType<typeof setTimeout> = undefined;

	const addExternalLoadCheck = () => {
		let id:number | undefined;
		setExternalLoadChecks((a) => {
			const loadChecks = [...a];
			loadChecks.push(false);
			id = loadChecks.length -1;
			return loadChecks;
		});
		// externalLoadChecks.push(false);
		// const id = externalLoadChecks.length - 1;		
		return id;
	};

	const delay = 1000;
	const completeExternalLoadCheck = (id:number) => {
		externalLoadChecks[id] = true;
		const timeDiff = Date.now() - startingTime;
		if(timeDiff >= delay){
			checkExternalLoadChecks();
		}
		else if(!delayedCheck){
			delayedCheck = setTimeout(() => {checkExternalLoadChecks();}, delay - timeDiff);
		}
	};

	const checkExternalLoadChecks = () => {
		let complete = true;

		externalLoadChecks.map((check) => {
			if(!check){
				complete = false;
			}
		});

		if(complete){
			setExternalDataLoaded(true);
		}
	};

	//#endregion


	// #dialogue image

	const [dialogueImage, setDialogueImage] = useState<string | null>(null);
	const [showDialogueImage, setShowDialogueImage] = useState<boolean>(false);

	//#region useStates

	const [ gameState, setGameState] = useState<number>(0);

	const [ lives, setLives] = useState<boolean[]>([true, true, true]);

	const [ externalDataLoaded , setExternalDataLoaded] = useState<boolean>(false);

	const [ tutorialStart, setTutorialStart] = useState<boolean>(false);

	const [ timerToggle, setTimerToggle] = useState<boolean>(true);

	const [ displayConsole, setDisplayConsole] = useState<boolean>(false);

	const [ activePannellumScene, setActivePannellumScene ] = useState<string>('');

	const [ activeDialogue, setActiveDialogue] = useState<string>('');

	const [ activePortoDialogue, setActivePortoDialogue] = useState<string>('');

	const [ showTaskCard, setShowTaskCard] = useState<boolean>(false);
	
	const [ showCards, setShowCards] = useState<boolean[]>([true, false, true, false, false]); // [0] = lives, [1] = timer, [2] = taskcard, [3] = porto, [4] = showimage

	//#endregion

	//#region useEffects
	
	useEffect(()=> {
		if(lives[2] == false){
			setGameState(5);
		}	
	},[lives]);

	useEffect(()=> {
		if(time <= 0){
			timerEnded();
		}
	},[time]);

	useEffect(() => {
		getFilesData();
	},[]);

	useEffect(() => {
		setGameState(scenarioData && scenarioData.length > 0 ? 1 : 0);
	}, [externalDataLoaded]);

	//#endregion

	//#region Passed Props

	const passedFunctions = {
		//scenes
		setActivePannellumScene,
		LoadScene,
		
		//dialogue
		setActiveDialogue,
		setActivePortoDialogue,

		//console
		setDisplayConsole,
		onSetDisplayConsole,
		onToggleDisplayConsole,

		// external data loading
		addExternalLoadCheck,
		completeExternalLoadCheck,

		//taskcard
		setShowTaskCard,
		setShowCards,

		//Lives
		takeLife,
		setLives,
		startTimer,
		endTimeEarly,
		stopTimer,
		setTimerToggle,

		//game state
		setGameState,

		//tutorial
		setTutorialStart,


		//files
		getFile,

		//dia image
		setDialogueImage,
		setShowDialogueImage,


	};

	const passedValues = {
		//scenes
		activePannellumScene,

		//dialogue
		activeDialogue,
		activePortoDialogue,

		//console
		displayConsole,

		// external data loading
		externalDataLoaded,

		//taskcad
		showTaskCard,
		showCards,

		//lives
		lives,
		time,
		startTime,
		countStartedTimers,
		timerToggle,
		
		//game start
		gameState,

		//tutorial
		tutorialStart,

		//dia image
		dialogueImage,
		showDialogueImage,

		//files
		files

	};

	//#endregion

	//#region render

	return (
		<GlobalContext.Provider value={{...passedValues, ...passedFunctions}}>
			{props.children}
		</GlobalContext.Provider>
	);

	//#endregion
};

const useGlobalContext = () => useContext(GlobalContext);

export {GlobalContextProvider, GlobalContext, useGlobalContext};
