import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
import { Paragraph } from '../Typography';
import Button from './Button';

 
const OptionButton = (props: {className?:string, children: ReactNode, onClick?: (p: any) => void, style: any}) => {
	return (  
		<StyledButton className={props.className} onClick={(p) => props.onClick && props.onClick(p)} style={props.style}>
			<Selection>
				<div/>
				<div/>
				<div/>
				<div/>
			</Selection>
			{props.children}
		</StyledButton>	
	);
};

const StyledButton = styled(Button)`

	border: solid 1px white;

	position: relative;
	color: white;
	background: none;
	box-sizing: border-box;

	transition: all .3s;

	&:hover,:focus{

		border-color: rgba(0,0,0,0);


		& > div {
			opacity: 1;
			transform: scale(1);
		}

		background-color: rgba(255,255,255,0.5);

	}

`;
const Selection = styled.div`

	position: absolute;
	left: -2.5px; top: -2.5px; right: -2.5px; bottom: -2.5px;

	/* border: solid 4px white; */

	opacity:0;

	transition: all .5s;
	transform: scaleX(1.02);

	& > div {
		position: absolute;

		height: 20px;
		width: 20px;

		background-color: white;

		display: flex;

		clip-path: polygon(20% 0%, 20% 80%, 100% 80%, 100% 100%, 0 100%, 0 0);
	}

	& :nth-child(1) { left:0; top:0; transform: rotate(90deg)}
	& :nth-child(2) { bottom:0; left:0;}
	& :nth-child(3) { right:0; top:0; transform: rotate(180deg)}
	& :nth-child(4) { bottom:0; right:0; transform: rotate(-90deg)}

`;
 
export default OptionButton;
