import styled from 'styled-components';
import { Option } from '../../../data/decisions';
import { DialogueChunkData } from '../../../services/directus';
import HesjeKiezen from './HesjeKiezen';
import ItemSelectie from './ItemSelectie';

const components = [
	ItemSelectie,
	HesjeKiezen,
];

const AdvancedDecisionComponent = (props : {dialogueChunk: DialogueChunkData, answer: (AnswerProps) => void}) => {

	const ADComponent = components[props.dialogueChunk.adc];

	return(
		<>
			{ ADComponent &&
				<Container>
					<ADComponent dialogueChunk={props.dialogueChunk} answer={(ap : AnswerProps) => props.answer(ap)}/>
				</Container>
			}
		</>

	);
};

// styled components

const Container = styled.div`
	width: 100%;
	flex-grow: 1;
	display: flex;
	/* background: yellow; */
`;

// types

export type AdvancedDecisionProps = {
	answer: (ap: AnswerProps) => void;
	dialogueChunk: DialogueChunkData;
}

export type AnswerProps = {
	answer:number, 
	adcOptions?: Option[]
}

export default AdvancedDecisionComponent;