import { PartialItem } from '@directus/sdk';
import styled from 'styled-components';
import { useTextContext } from '../../context/text/TextContext';
import { DialogueChunkData, DialogueOptionData } from '../../services/directus';
import OptionButton from '../Buttons/OptionButton';
import { OptionText } from '../Typography';
import OptionsContainer from './components/OptionsContainer';

const DialogueOptions = (props: {dialogueChunk: DialogueChunkData, options: PartialItem<DialogueOptionData>[] | null | undefined, sortedOptions: PartialItem<DialogueOptionData>[] | null | undefined,  answer: (a : number) => void}) => {
	
	const {t} = useTextContext();
	
	return (  
		<OptionsContainer singleRow={props.dialogueChunk.decisions_singlerow ? true : false}>
			{ props.sortedOptions && props.sortedOptions.map((option, index) => 
				<Option key={`${option.id}-optiondialogueentry`} onClick={() => props.answer(index)} /* style={{order: `${option.order !== undefined ? option.order + 1 : ''}`}} */>
					<OptionText>
						{option.text && t(option.text)}
					</OptionText>
				</Option>
			)}
		</OptionsContainer>
	);
};

// key frames

// styled components

const Option = styled(OptionButton)`
	/* margin: 4px; */
	box-sizing: border-box;
	width: 100%;

	& > p {
		
		@media (max-width: ${p => p.theme.responsive.media.lg}){
			margin: ${p => p.theme.responsive.padding.sm / 4}px 0!important;
			/* font-size: 0.75rem; */
		}

		@media (min-width: ${p => p.theme.responsive.media.lg}){
			margin: ${p => p.theme.responsive.padding.lg / 4}px 0!important;
			/* font-size: 1rem; */

		}

		@media (min-width: ${p => p.theme.responsive.media.xl}){
			margin: ${p => p.theme.responsive.padding.xl / 2}px 0!important;
			/* font-size: 1rem; */
		}

	}
`;

const OptionsContent = styled.div`
	position: relative;
	width: 100%;
	overflow-y: auto;
	max-height: 100%;
`;

export default DialogueOptions;
