declare module 'styled-components' {
	export interface DefaultTheme {
		accentcolors: {
			GGBpurple: string,
			GGBblue: string,
			GGBgreen: string,
			GGByellow: string,
			GGBorange: string,
			GGBbrown: string
		},
		maincolors: {
			GGBblue: string,
			GGBcyan: string,
			GGBred: string,
		},
		responsive:{
			media: ResponsiveProp;
			padding: ResponsiveProp;
		}
	}
}

type ResponsiveProp = {
	xs: string,
	sm: string,
	lg: string,
	xl: string,
}

const Theme = {
	accentcolors: {
		GGBpurple: '#491498',
		GGBblue: '#008cd8',
		GGBgreen: '#339911',
		GGByellow: '#ffce00',
		GGBorange: '#e27000',
		GGBbrown: '#603911'
	},
	maincolors: {
		GGBblue: '#12439B',
		GGBcyan: '#50BBBB',
		GGBred: '#E53C1E',
	},
	responsive:{
		media : {
			xs: '400px',
			sm: '600px',
			lg: '1000px',
			xl: '1440px',
		},
		padding : {
			xs: '25',
			sm: '25',
			lg: '50',
			xl: '50',
		}
	}
};

export default Theme;