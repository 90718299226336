import styled from 'styled-components';
import { useGlobalContext } from '../../context/global';
import { taskDataAvailable } from '../../data/scenarioData';
import EndScreen from './Endscreen/EndScreen';
import GameoverScreen from './GameoverScreen/GameoverScreen';
import IntroductionScreen from './IntroductionScreen/IntroductionScreen';
import ScoreScreen from './ScoreScreen/ScoreScreen';
import StartScreen from './StartScreen/StartScreen';

const Screens = () => {

	const {gameState, getFile} = useGlobalContext();

	const background = getFile && getFile('e881647a-4959-4c99-823a-5c8ce98f8ec2');

	return(
		<>
			{ (gameState !== 0) && 
				<Container url={background}>
					<div>
						{ 
							gameState === 1 ? <StartScreen/> :
								gameState === 2 ? <IntroductionScreen/>:
									gameState === 3 ? <ScoreScreen/> :
										// gameState === 4 && taskDataAvailable() ? <EndScreen/> :
										gameState === 5 ? <GameoverScreen/> :
											<></>
						}
					</div>
				</Container>
			}
		</>
	);
};

// styled components

const Container = styled.div<{url: string | undefined}>`

	position: absolute;
	left:0;top:0;right:0;bottom:0;
	height: 100%;
	width: 100%;

	z-index: 2;

    background-image: url(${p => p.url});
	background-size: cover;
	& > div {
		background-color: rgba(255, 255, 255, 0.2);
		//backdrop-filter: brightness(1.5) saturate(0.5);
		width: 100%;
		height: 100%;
	}	
`;

export default Screens;