import { ReactNode } from 'react';
import styled from 'styled-components';
import { useGlobalContext } from '../../context/global';
import BasicFrame from '../Frames/BasicFrame';
import Button from './Button';

const CloseButton = (props: {children? : ReactNode, onClick?: () => void}) => {

	const {lives, takeLife, getFile} = useGlobalContext();

	const close = getFile && getFile('0550eb4c-9bc4-415a-8a45-a8e8887c737e');

	return(
		<Close url={close} onClick={props.onClick}>
			<StyledButtonFrame>
				{props.children}
			</StyledButtonFrame>
		</Close>
	);
};


const Close = styled(Button)<{url: string | undefined}>`
	background-color: white;
	width: 50px;
	height: 50px;

    background-image: url(${p => p.url});
    background-size: 50% 50%;
    background-repeat: no-repeat;
    background-position: center;
	//transform: translateX(150%);
	cursor: pointer;

    &:hover{
        background-size: 60% 60%;
    }
`;

const StyledButtonFrame = styled(BasicFrame)`
	background: none;
	&:after{
		border: 1px solid ${p => p.theme.maincolors.GGBblue};    
        left: 3px; top: 3px; right: 3px; bottom: 3px;
	}
	width: 100%;
	height: 100%;
	padding:0;
`;



export default CloseButton;