import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { keyframes } from 'styled-components';
import { useGlobalContext } from '../../../context/global';
import { fadeInHorizontal, fadeInVertical, fadeOutHorizontal, fadeOutVertical } from '../animations';

const Timer= () => {

	const {time, startTime, showCards, getFile, countStartedTimers} = useGlobalContext();
	const redCircle = getFile && getFile('3821d829-28ab-48f2-9959-8049b80db1f6');
	const blueCircle = getFile && getFile('7d24dbb8-5dd3-41ca-83bb-bb8b6802ef93');

	const timeEnding = time && time <= 10 || time == 0 ? true : false;
	
	const part = startTime && 100 / startTime ;
	const [calculation, setCalculation] = useState<number>(0);

	useEffect(() => {
		setCalculation(0);
	},[countStartedTimers]);
	
	useEffect(() => {
		if(part)
			setCalculation(0 - part);
	},[part]);

	useEffect(() => {
		if(part)
			setCalculation(e => e + part);
		if(calculation >= 100 ){
			setCalculation(0);
		}
		if(time == 0){
			setCalculation(100);
		}
	},[time]);



	return (<>
		<Container state={showCards && showCards[1]}>
			<TimerBox url={timeEnding ? redCircle : blueCircle}/>
			<TimerBoxVague calculation={calculation}/>				
			<Time timeEnding={timeEnding}>
				{time}
			</Time>
		</Container>
	</>
	);
};

//styled components
const Container = styled.div<{state: boolean}>`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 100%;
	height: 100%;

	//mobile
	@media (max-width: ${p => p.theme.responsive.media.lg}){
		animation: ${p => p.state? fadeInVertical : fadeOutVertical } 2s forwards;
    }

    //desktop
	@media (min-width: ${p => p.theme.responsive.media.lg}){
		animation: ${p => p.state? fadeInHorizontal : fadeOutHorizontal } 2s forwards;
    }
	
`;

const TimerBox = styled.div<{url: string | undefined}>`
    background-image: url(${p => p.url});
	
	background-size: 90% 90%;
	background-repeat: no-repeat;
	background-position: center;

	transform: rotate(10deg);

	background-color:white;
	border-radius: 50%;
	opacity: none;
	width: 100%;
	height: 100%;

`;

const TimerBoxVague = styled.div<{calculation: number}>`

	position: absolute;
	-webkit-mask-image: conic-gradient(#000 ${p => p.calculation + '%'}, transparent 0%);
	opacity: 0.5;

	background-color:white;
	border-radius: 50%;
	width: 100%;
	height: 100%;

`;

const Time = styled.div<{timeEnding: boolean}>`

	font-size: 4vh;
	font-weight: bold;
	color: ${p => p.timeEnding ? 'red' : p => p.theme.maincolors.GGBblue };

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	position: absolute;
	border-radius: 50%;
	opacity: none;
	width: 100%;
	height: 100%;

`;
export default Timer;