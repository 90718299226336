import styled from 'styled-components';
import { useGlobalContext } from '../../../context/global';

 
const LoadingScreen = () => {

	const {externalDataLoaded} = useGlobalContext();

	return (  
		<>
			{ !externalDataLoaded &&
			<>

				<Container>
					<div style={{margin:'auto'}}>loading...</div>
				</Container>

			</>
			}
		</>
	);
};
 
// styled components

const Container = styled.div`
	position: absolute;
	left:0;
	top:0;
    height: 100%;
    width: 100%;
    background: pink;

    display: flex;

	z-index: 2000;
`;
 
export default LoadingScreen;