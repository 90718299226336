import { createGlobalStyle } from 'styled-components';
import { FacitRegular } from '../assets/fonts';

// eslint-disable-next-line
const fonts = require('../assets/fonts/fonts.css');

const GlobalStyle = createGlobalStyle`

	${fonts};

	html {
		height: -webkit-fill-available;
	}

	body {
		min-height: 100vh;
		min-height: -webkit-fill-available;
		font-family: 'Facit';
		margin:0;
		padding:0;
		overflow: hidden;
		display: flex;
	}

  	#root {
		width: 100%;
		position: relative;
	}

  .navigate {
    height: 50px;
    width: 100px;
    background: white;
    border-radius: 50%;
    border: 3px solid white;
    cursor: pointer!important;
    background-image:${`url(https://tqwi-p21-04.directus.app/assets/f34d0b49-a67f-4002-b5f0-ba5d00815477.png?${new Date().getTime()})`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: 70%;
    user-select: none;
	  transition: background-size 0.2s, border 0.2s;
      &:hover{
        background-size: 80%;
      }
      &:hover::after {
        border: 3px solid ${p => p.theme.maincolors.GGBblue};
      }
  }
  .navigate:after {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: 50%;
    border: 2px solid ${p => p.theme.maincolors.GGBblue};
  }

	.dialogue {
    height: 25px;
    width: 25px;
    background: white;
    border-radius: 50%;
    border: 3px solid white;
    cursor: pointer!important;
    user-select: none;
    &:hover::after {
      border: 3px solid ${p => p.theme.maincolors.GGBblue};
    }
    }
  
  .dialogue:after {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: 50%;
    border: 2px solid ${p => p.theme.maincolors.GGBblue};
  }

	.overlay {
    height: 25px;
    width: 25px;
    background: white;
    border-radius: 50%;
    border: 3px solid white;
    cursor: pointer!important;
    user-select: none;
      &:hover::after {
        border: 3px solid ${p => p.theme.maincolors.GGBblue};
      }
    }
    
  .overlay:after {
      content: '';
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      border-radius: 50%;
      border: 2px solid ${p => p.theme.maincolors.GGBblue};
  }

  //region decision hotspot
  .decision {
      height: 10px;
      width: 10px;
      background-color: ${p => p.theme.maincolors.GGBblue};      
      border-radius: 100%;

      font-weight: bold;
      font-family: 'Facit';
      font-size: 1.5em;
      pointer-events: none;

    }

  .decision > div {
      height: 100px;
      position: absolute;
      left:50%;
      top:0;
      transform: translate(-2px, -100%);
    }

  .decision-wrapper {
      border-left: solid 4px ${p => p.theme.maincolors.GGBblue};
      height: 100%;

      position: relative;

      animation: fadeIn 2s;
      transform-origin: bottom center;

      pointer-events: none;
      display: flex;
      flex-direction: column;
    }
  
  	.decision-wrapper > button {
      	border: none;
      	outline: none;
		background: none;

		/* width: 300px; */
    white-space: nowrap;
    padding-inline: 2rem;
		height: 60px;
		position: relative;
		pointer-events: all;
		color: white;
		font-weight: bold;
		transform: translate(-40px, -60px);
		cursor: pointer;
    font-size: 1.2rem;
	}

	.decision-wrapper > button::after {
		content: '';
		position: absolute;
		/* background: green; */
		left: 4px; top: 4px; right: 4px; bottom: 4px;
		border: 2px solid white;
		opacity: 0.5;
	}
	

	.decision-wrapper > button > .decision-button-backdrop {
		background: rgba(155,155,155,0.5);
		position: absolute;
		backdrop-filter: blur(2px);
		left:0 ;top: 0; right: 0; bottom: 0;
		z-index:-1;
	}

	.decision-wrapper > button:hover::after, button:focus::after	{
		background: white;
	}

	.decision-wrapper > button:hover > .decision-selection{
		opacity: 1;
		transform: scale(1);
	}

	.decision-selection{

		margin: 4px;

		position: absolute;
		left: -1.75px; top: -1.75px; right: -1.75px; bottom: -1.75px;

		/* border: solid 4px white; */

		opacity:0;

		transition: all .5s;
		transform: scaleX(1.02);

		& > div {
			position: absolute;

			height: 15px;
			width: 15px;

			background-color: white;

			display: flex;

			clip-path: polygon(30% 0%, 30% 70%, 100% 70%, 100% 100%, 0 100%, 0 0);
		}

		& :nth-child(1) { left:0; top:0; transform: rotate(90deg)}
		& :nth-child(2) { bottom:0; left:0;}
		& :nth-child(3) { right:0; top:0; transform: rotate(180deg)}
		& :nth-child(4) { bottom:0; right:0; transform: rotate(-90deg)}
	}

  @keyframes fadeIn {
      from {transform: scale(1,0)};
      to {transform: scale(1,1)};
  }

  //#endregion

  // #region pannellum basics
  .pnlm-panorama-info {
      display: none!important;
  }

  .pnlm-load-box{
      display: none!important;
  }

  .pnlm-about-msg {
      display: none!important;
  }

  .pnlm-orientation-button {
	  position: absolute;
	  left:0;
	  bottom:0;
  }
  //#endregion

`;

export default GlobalStyle;
