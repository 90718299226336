import { ID } from '@directus/sdk';

export type Option = {
    option: string | undefined,
    feedback: string | undefined,
}

export type DecisionData = {
    description: string,
    options: Option[], 
    correct_option: number,
    chosen_option: number| null, //can be empty when not chosen
    task: ID,
    adc: boolean
}
const decisions: DecisionData[] = [];

const addDecisionItem = (item: DecisionData) => {
	decisions.push(item);
};


export {addDecisionItem, decisions};