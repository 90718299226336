import React, {useState, useRef, ReactComponentElement, ReactNode, useEffect} from 'react';

import styled from 'styled-components';
import { useGlobalContext } from '../../context/global';
import { useTextContext } from '../../context/text/TextContext';
import ConsoleDialogueSelect from './components/DialogueSelect';
import ConsoleGameStateSelect from './components/GameStateSelect';
import ConsoleSceneSelect from './components/SceneSelect';
import Toggles from './components/Toggles';

const tabs: any  = {
	'scene' : ConsoleSceneSelect,
	'dialogue' : ConsoleDialogueSelect,
	'game state' : ConsoleGameStateSelect,
	'toggles' : Toggles,
};

const Console = () => {
	const {displayConsole, externalDataLoaded} = useGlobalContext();
	const {t} = useTextContext();
	
	const [activeInterfaceItem, setActiveInterfaceItem] = useState<string>('scene');
	const selectTabRef = useRef<HTMLSelectElement>(null);
	const [tabLabels, setTabLabels] = useState<string[] | undefined>();

	useEffect(() => {
		if(!tabLabels){
			const labels: string[] = [];
			for (const [key] of Object.entries(tabs)) {
				labels.push(key);
			}
			setTabLabels(labels);
		}
	}, []);

	const setActiveTab = (key: string) => {
		setActiveInterfaceItem(key);
	};

	//const [ActiveTab, setActiveTab] = useState<ReactNode | undefined>();
	const ActiveTab = tabs[activeInterfaceItem];
	// Render Function
	return (
		<> 
			{displayConsole && externalDataLoaded &&
				<ConsoleContainer>
					<ConsoleWrap>

						<ConsoleTitle>{t('console.title')}</ConsoleTitle>
			
						{/* <select onChange={(e) => setActiveInterfaceItem(e.target.value)} ref={selectTabRef} value={activeInterfaceItem}>
							{tabLabels && tabLabels.map((opt) => (<option key={opt} label={String(opt)} value={opt}/>))}
						</select> */}
						<TabContainer>
							{ tabs && Object.keys(tabs).map((key, index) => (
								<button style={activeInterfaceItem === key ? {outline: 'solid 2px cyan'} : {}} key={`console-tabs-${index}-${key}`} onClick={() => setActiveTab(key)}>{key}</button>
							))}
						</TabContainer>				
						{ ActiveTab &&
							<ActiveTab/>
						}
						
					</ConsoleWrap>
				</ConsoleContainer>
			}
		</>
	);
	
};


//#region styled components
const ConsoleTitle = styled.div`
	font-size: 1.5rem;
	font-weight: bold;
`;

const ConsoleContainer = styled.div`
    z-index: 10;
    position: absolute;
    right:0;
    top:0;
	height: auto;
    display: flex;
    justify-content: center;
	width:100vw;
	text-align: left;
`;

const ConsoleWrap = styled.div`
    z-index: 5;
    width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
    opacity: 1;
    color: white;
    overflow: auto;
    text-align: left;
	padding: 20px;
`;

const TabContainer = styled.div`

	& > button {
		margin-right: 10px;
	}

`;

//#endregion

export default Console;

