import porto from './porto.mp3';
import portoClick from './portoClick.mp3';

import ambulance_01_01 from './Ambulance/Ambulance_01_01.mp3';
import ambulance_02_01 from './Ambulance/Ambulance_02_01.mp3';
import ambulance_03_01 from './Ambulance/Ambulance_03_01.mp3';
import ambulance_04_01 from './Ambulance/Ambulance_04_01.mp3';
import ambulance_05_01 from './Ambulance/Ambulance_05_01.mp3';
import ambulance_06_01 from './Ambulance/Ambulance_06_01.mp3';
import ambulance_07_01 from './Ambulance/Ambulance_07_01.mp3';
import ambulance_08_01 from './Ambulance/Ambulance_08_01.mp3';

import hena_P1_01_01 from './Hena/Hena_p1_01_01.mp3';
import hena_P1_02_01 from './Hena/Hena_p1_02_01.mp3';
import hena_P1_03_01 from './Hena/Hena_p1_03_01.mp3';
import hena_P2_01_01 from './Hena/Hena_p2_01_01.mp3';
import hena_P2_02_01 from './Hena/Hena_p2_02_01.mp3';
import hena_P2_03_01 from './Hena/Hena_p2_03_01.mp3';

import kruispunt_P1_01_01 from './Kruispunt/Kruispunt_P1_01_01.mp3';
import kruispunt_P1_02_01 from './Kruispunt/Kruispunt_P1_02_01.mp3';
import kruispunt_P2_01_01 from './Kruispunt/Kruispunt_P2_01_01.mp3';
import kruispunt_P2_02_01 from './Kruispunt/Kruispunt_P2_02_01.mp3';
import kruispunt_P3_01_01 from './Kruispunt/Kruispunt_P3_01_01.mp3';
import kruispunt_P3_02_01 from './Kruispunt/Kruispunt_P3_02_01.mp3';
import kruispunt_P4_01_01 from './Kruispunt/Kruispunt_P4_01_01.mp3';
import kruispunt_P4_02_01 from './Kruispunt/Kruispunt_P4_02_01.mp3';
import kruispunt_P5_01_01 from './Kruispunt/Kruispunt_P5_01_01.mp3';
import kruispunt_P5_02_01 from './Kruispunt/Kruispunt_P5_02_01.mp3';
import kruispunt_P6_01_01 from './Kruispunt/Kruispunt_P6_01_01.mp3';
import kruispunt_P6_02_01 from './Kruispunt/Kruispunt_P6_02_01.mp3';
import kruispunt_P7_01_01 from './Kruispunt/Kruispunt_P7_01_01.mp3';
import kruispunt_P7_02_01 from './Kruispunt/Kruispunt_P7_02_01.mp3';
import kruispunt_P8_01_01 from './Kruispunt/Kruispunt_P8_01_01.mp3';

import loodspost_01_01 from './Loodspost/Loodspost_01_01.mp3';
import loodspost_02_01 from './Loodspost/Loodspost_02_01.mp3';
import loodspost_03_01 from './Loodspost/Loodspost_03_01.mp3';
import loodspost_04_01 from './Loodspost/Loodspost_04_01.mp3';

import meldkamer_P1_01_01 from './Meldkamer/Meldkamer_P1_01_01.mp3';

import opstelplaats_P1_01_01 from './Opstelplaats/Opstelplaats_P1_01_01.mp3';
import opstelplaats_P1_02_01 from './Opstelplaats/Opstelplaats_P1_02_01.mp3';
import opstelplaats_P1_03_01 from './Opstelplaats/Opstelplaats_P1_03_01.mp3';
import opstelplaats_P1_04_01 from './Opstelplaats/Opstelplaats_P1_04_01.mp3';
import opstelplaats_P2_01_01 from './Opstelplaats/Opstelplaats_P2_01_01.mp3';
import opstelplaats_P2_02_01 from './Opstelplaats/Opstelplaats_P2_02_01.mp3';
import opstelplaats_P2_03_01 from './Opstelplaats/Opstelplaats_P2_03_01.mp3';
import opstelplaats_P2_04_01 from './Opstelplaats/Opstelplaats_P2_04_01.mp3';

import OVDG_P1_01_01 from './OVDG/OVDG_P1_01_01.mp3';
import OVDG_P2_01_01 from './OVDG/OVDG_P2_01_01.mp3';

import snelweg_P1_01_01 from './Snelweg/Snelweg_P1_01_01.mp3';

import sporthal_P1_01_01 from './Sporthal/Sporthal_P1_01_01.mp3';
import sporthal_P1_02_01 from './Sporthal/Sporthal_P1_02_01.mp3';
import sporthal_P2_01_01 from './Sporthal/Sporthal_P2_01_01.mp3';
import sporthal_P2_02_01 from './Sporthal/Sporthal_P2_02_01.mp3';
import sporthal_P3_01_01 from './Sporthal/Sporthal_P3_01_01.mp3';
import sporthal_P4_01_01 from './Sporthal/Sporthal_P4_01_01.mp3';

import terras_P1_01_01 from './Terras/Terras_P1_01_01.mp3';
import terras_P1_02_01 from './Terras/Terras_P1_02_01.mp3';
import terras_P1_03_01 from './Terras/Terras_P1_03_01.mp3';
import terras_P1_04_01 from './Terras/Terras_P1_04_01.mp3';

//#region dialogen
import loodspost_Aanrijdend_Ambulance08_Dialoog1_Hesjes from './Dialogen/Loodspost/Loodspost_Aanrijdend_Ambulance08_Dialoog1_Hesjes.mp3';
import loodspost_informeren_loodspost02_dialoog1 from './Dialogen/Loodspost/Loodspost_informeren_loodspost02_dialoog1.2.mp3';
import loodspost_Schakelen_ambulance08_Dialoog4 from './Dialogen/Loodspost/Loodspost_Schakelen_ambulance08_Dialoog4.mp3';
import loodspost_Transport_Loodspost_03_Dialoog2_Eind from './Dialogen/Loodspost/Loodspost_Transport_Loodspost_03_Dialoog2_Eind.mp3';
import loodspost_Transport_Loodspost_03_Dialoog2a_Eind from './Dialogen/Loodspost/Loodspost_Transport_Loodspost_03_Dialoog2a_Eind.mp3';

import OvDG_Aanrijdend_Kruispunt_P6_01_Dialoog1 from './Dialogen/OvD-G/OvD-G_Aanrijdend_Kruispunt_P6_01_Dialoog1.mp3';
import OvDG_Aanrijdend_OvDG_P2_01_Dialoog2_Inzet from './Dialogen/OvD-G/OvD-G_Aanrijdend_OvD-G_P2_01_Dialoog2_Inzet.mp3';
import OvDG_Aanrijdend_OvDG_P2_01_Dialoog2 from './Dialogen/OvD-G/OvD-G_Aanrijdend_OvD-G_P2_01_Dialoog2.mp3';
import OVDG_Aanrijdend_P2_01_dialoog_0 from './Dialogen/OvD-G/OVD-G_Aanrijdend_P2_01_dialoog_0.mp3';
import OvDG_Coördineren_Kruispunt_P3_01_Dialoog6 from './Dialogen/OvD-G/OvD-G_Coördineren_Kruispunt_P3_01_Dialoog6.mp3';
import OvDG_Indeling1_OpstelplaatsP1_02_Dialoog3b from './Dialogen/OvD-G/OvD-G_Indeling1_OpstelplaatsP1_02_Dialoog3b.mp3';
import OvDG_schakelen_OvDG_P1_01_Dialoog4 from './Dialogen/OvD-G/OvD-G_schakelen_OvD-G_P1_01_Dialoog4.mp3';
import OvDG_schakelen_OvDG_P1_01_Dialoog2 from './Dialogen/OvD-G/OvD-G_Schakelen_OvD-G_P1_02_Dialoog2.mp3';
import OvDG_TweedeOvDG_OpstelplaatsP1_03_Dialoog2_Eind from './Dialogen/OvD-G/OvD-G_TweedeOvD-G_OpstelplaatsP1_03_Dialoog2_Eind.mp3';

import secundair_organiseren_opstelplaats_p2_04_dialoog from './Dialogen/Secundaire Triage (10)/secundair_organiseren_opstelplaats_p2_04_dialoog.mp3';
import secundair_registratie_sporthal_p2_01_dialoog2 from './Dialogen/Secundaire Triage (10)/secundair_registratie_sporthal_p2_01_dialoog2.mp3';
import secundair_Registratie_sporthal_p2_01_dialoog2a from './Dialogen/Secundaire Triage (10)/Secundair_Registratie_sporthal_p2_01_dialoog2a.mp3';
import secundair_Schakelen_Ambulance_05_Dialoog2_portofoon_groepkiezen from './Dialogen/Secundaire Triage (10)/Secundair_Schakelen_Ambulance_05_Dialoog2_portofoon_groepkiezen.mp3';

import transport_Ambulancebijstandsplan_Meldkamer_P1_01_Dialoog1_OptieBijstand_Feedback from './Dialogen/Transport/Transport_Ambulancebijstandsplan_Meldkamer_P1_01_Dialoog1_OptieBijstand_Feedback.mp3';
import transport_Ambulancebijstandsplan_Meldkamer_P1_01_Dialoog1 from './Dialogen/Transport/Transport_Ambulancebijstandsplan_Meldkamer_P1_01_Dialoog1.mp3';
import transport_Ambulancebijstandsplan_Meldkamer_P1_01_Dialoog2 from './Dialogen/Transport/Transport_Ambulancebijstandsplan_Meldkamer_P1_01_Dialoog2.mp3';
import transport_Ambulancebijstandsplan_Meldkamer_P1_01_Dialoog3 from './Dialogen/Transport/Transport_Ambulancebijstandsplan_Meldkamer_P1_01_Dialoog3.mp3';
import transport_Ambulancebijstandsplan_Meldkamer_P1_01_Dialoog4 from './Dialogen/Transport/Transport_Ambulancebijstandsplan_Meldkamer_P1_01_Dialoog4.mp3';
import transport_Ambulancebijstandsplan_Meldkamer_P1_01_Dialoog6 from './Dialogen/Transport/Transport_Ambulancebijstandsplan_Meldkamer_P1_01_Dialoog6.mp3';
import transport_Ambulancebijstandsplan_Meldkamer_P1_01_Dialoog8f from './Dialogen/Transport/Transport_Ambulancebijstandsplan_Meldkamer_P1_01_Dialoog8f.mp3';
import transport_Ambulancebijstandsplan_Meldkamer_P1_01_Dialoog9 from './Dialogen/Transport/Transport_Ambulancebijstandsplan_Meldkamer_P1_01_Dialoog9.mp3';
import transport_Gewondenspreiding_Meldkamer_P1_01_Dialoog12_Eind from './Dialogen/Transport/Transport_Gewondenspreiding_Meldkamer_P1_01_Dialoog12_Eind.mp3';
import transport_Melding_Meldkamer_P1_01_Dialoog1 from './Dialogen/Transport/Transport_Melding_Meldkamer_P1_01_Dialoog1.mp3';
import transport_Melding_Meldkamer_P1_01_Dialoog20 from './Dialogen/Transport/Transport_Melding_Meldkamer_P1_01_Dialoog20.mp3';
import transport_Transport_Meldkamer_P1_01_Dialoog1 from './Dialogen/Transport/Transport_Transport_Meldkamer_P1_01_Dialoog1.mp3';
import transport_Transport_Meldkamer_P1_01_Dialoog2 from './Dialogen/Transport/Transport_Transport_Meldkamer_P1_01_Dialoog2.mp3';
import transport_Verbindingen_Meldkamer_P1_01_Dialoog1 from './Dialogen/Transport/Transport_Verbindingen_Meldkamer_P1_01_Dialoog1.mp3';
import transport_Verbindingen_Meldkamer_P1_01_Dialoog2 from './Dialogen/Transport/Transport_Verbindingen_Meldkamer_P1_01_Dialoog2.mp3';
import transport_Verbindingen_Meldkamer_P1_01_Dialoog3 from './Dialogen/Transport/Transport_Verbindingen_Meldkamer_P1_01_Dialoog3.mp3';

import treatment_aanrijdend_ambulance_05_hesjekiezen from './Dialogen/Treatment/treatment_aanrijdend_ambulance_05_hesjekiezen.mp3';
import treatment_organiseren_hena_p1_02_2_dialoog2 from './Dialogen/Treatment/treatment_organiseren_hena_p1_02_2_dialoog2.mp3';
import treatment_schakelen_ambulance_05_dialoog2_portofoon_groepkiezen from './Dialogen/Treatment/treatment_schakelen_ambulance_05_dialoog2_portofoon_groepkiezen.mp3';
import treatment_taken_hena_p2_01_dialoog1 from './Dialogen/Treatment/treatment_taken_hena_p2_01_dialoog1.mp3';
import treatment_taken_hena_p2_01_dialoog4 from './Dialogen/Treatment/treatment_taken_hena_p2_01_dialoog4.mp3';
import treatment_transport_HENA_P1_03_dialoog2 from './Dialogen/Treatment/treatment_transport_HENA_P1_03_dialoog2.mp3';
import treatment_transport_HENA_P1_03_dialoog2a from './Dialogen/Treatment/treatment_transport_HENA_P1_03_dialoog2a.mp3';

import triage_aanrijdend_ambulance_05_dialoog3_hesjekiezen_karl from './Dialogen/Triage/triage_aanrijdend_ambulance_05_dialoog3_hesjekiezen_karl.mp3';
import triage_aanrijdend_ambulance_05_spullenverzamelen from './Dialogen/Triage/triage_aanrijdend_ambulance_05_spullenverzamelen.mp3';
import triage_schakelen_ambulance_05_dialoog2_portofoon_groepkiezen from './Dialogen/Triage/Triage_schakelen_ambulance_05_dialoog2_portofoon_groepkiezen.mp3';
import triage_triage_terras_p1_03_dialoog3 from './Dialogen/Triage/triage_triage_terras_p1_03_dialoog3.mp3';
import triage_triage_terras_p1_03_dialoog3a from './Dialogen/Triage/triage_triage_terras_p1_03_dialoog3a.mp3';
import triage_uitvoeren_opstelplaats_p1_03_dialoog1 from './Dialogen/Triage/triage_uitvoeren_opstelplaats_p1_03_dialoog1.mp3';
import triage_uitvoeren_opstelplaats_p1_03_dialoog2 from './Dialogen/Triage/triage_uitvoeren_opstelplaats_p1_03_dialoog2.mp3';
import triage_uitvoeren_opstelplaats_p1_03_dialoog4 from './Dialogen/Triage/triage_uitvoeren_opstelplaats_p1_03_dialoog4.mp3';
import triage_uitvoeren_opstelplaats_p1_03_dialoog5 from './Dialogen/Triage/triage_uitvoeren_opstelplaats_p1_03_dialoog5.mp3';

import tutorial01_dialoog1_01 from './Dialogen/Tutorial/tutorial01-dialoog1-01 .mp3';
import tutorial01_uitleg_01 from './Dialogen/Tutorial/tutorial01-uitleg-01 .mp3';
import tutorial01_uitleg02 from './Dialogen/Tutorial/tutorial02-uitleg02 .mp3';
import tutorialV2_Ambulance02_2_Karl from './Dialogen/Tutorial/TutorialV2_Ambulance02_2_Karl .mp3'; 
import tutorialV2_Ambulance02_HotspotDialoog2 from './Dialogen/Tutorial/TutorialV2_Ambulance02_HotspotDialoog2 .mp3'; 
import tutorialV2_Ambulance02_Karl from './Dialogen/Tutorial/TutorialV2_Ambulance02_Karl .mp3';
import tutorialV2_Kruispunt_Dialoog3 from './Dialogen/Tutorial/TutorialV2_Kruispunt_ Dialoog3.mp3';
import tutorialV2_Kruispunt_Dialoog4 from './Dialogen/Tutorial/TutorialV2_Kruispunt_Dialoog4.mp3';
import tutorialV2_Kruispunt_Dialoog5 from './Dialogen/Tutorial/TutorialV2_Kruispunt_Dialoog5 .mp3';
import tutorialV2_Kruispunt_Dialoog6 from './Dialogen/Tutorial/TutorialV2_Kruispunt_Dialoog6.mp3';
import tutorialV2_Kruispunt_Dialoog7 from './Dialogen/Tutorial/TutorialV2_Kruispunt_Dialoog7 .mp3';

import ACH_Aanrijdend_Ambulance02_Dialoog2 from './Dialogen/TV Command & Control - Ambulancechauffeur/ACH_Aanrijdend_Ambulance02_Dialoog2.mp3';
import ACH_Aanrijdend_Ambulance02_Dialoog3_HesjeKiezen_Karl from './Dialogen/TV Command & Control - Ambulancechauffeur/ACH_Aanrijdend_Ambulance02_Dialoog3_HesjeKiezen_Karl.mp3';
import ACH_Aanrijdend_Ambulance04_BepaalRoute from './Dialogen/TV Command & Control - Ambulancechauffeur/ACH_Aanrijdend_Ambulance04_BepaalRoute.mp3';
import ACH_Aanrijdend_Ambulance04_BepaalRoute2 from './Dialogen/TV Command & Control - Ambulancechauffeur/ACH_Aanrijdend_Ambulance04_BepaalRoute2.mp3';
import ACH_Aanrijdend_Ambulance04_dialoog_0 from './Dialogen/TV Command & Control - Ambulancechauffeur/ACH_Aanrijdend_Ambulance04_dialoog_0.mp3';
import ACH_Aanrijdende_Ambulance04_dialoog2 from './Dialogen/TV Command & Control - Ambulancechauffeur/ACH_Aanrijdende_Ambulance04_dialoog2.mp3';
import ACH_Indeling_Dialoog2 from './Dialogen/TV Command & Control - Ambulancechauffeur/ACH_Indeling_Dialoog2.mp3';
import ACH_Indeling_Dialoog4 from './Dialogen/TV Command & Control - Ambulancechauffeur/ACH_Indeling_Dialoog4.mp3';
import ACH_Indeling_Overzichtsschets_aan_en_afvoerroutes_2_fout from './Dialogen/TV Command & Control - Ambulancechauffeur/ACH_Indeling_Overzichtsschets_aan-en-afvoerroutes_2_fout.mp3';
import ACH_Indeling_Overzichtsschets_aan_en_afvoerroutes_2 from './Dialogen/TV Command & Control - Ambulancechauffeur/ACH_Indeling_Overzichtsschets_aan-en-afvoerroutes_2.mp3';
import ACH_Indeling_Overzichtsschets_Opstelplaats_2 from './Dialogen/TV Command & Control - Ambulancechauffeur/ACH_Indeling_Overzichtsschets_Opstelplaats_2.mp3';
import ACH_Schakelen_Ambulance02_Dialoog1_Portofoon_GroepKiezen from './Dialogen/TV Command & Control - Ambulancechauffeur/ACH_Schakelen_Ambulance02_Dialoog1_Portofoon_GroepKiezen.mp3';
import Karl1 from './Dialogen/TV Command & Control - Ambulancechauffeur/Karl1.mp3';
import Karlfinish from './Dialogen/TV Command & Control - Ambulancechauffeur/Karlfinish.mp3';

import AVP_Aanrijdend_Ambulance04_dialoog_0 from './Dialogen/TV Command & Control - Ambulanceverpleegkundige/AVP_Aanrijdend_Ambulance04_dialoog_0.mp3';
import AVP_Aanrijdend_Ambulance06_dialoog2 from './Dialogen/TV Command & Control - Ambulanceverpleegkundige/AVP_Aanrijdend_Ambulance06_Dialoog2.mp3';
import AVP_Aanrijdend_Ambulance06_dialoog3 from './Dialogen/TV Command & Control - Ambulanceverpleegkundige/AVP_Aanrijdend_Ambulance06_Dialoog3.mp3';
import AVP_Aanrijdend_Ambulance06_dialoog4 from './Dialogen/TV Command & Control - Ambulanceverpleegkundige/AVP_Aanrijdend_Ambulance06_Dialoog4.mp3';
import AVP_Inrichting_KruispuntP6_01_Dialoog1_Secundair from './Dialogen/TV Command & Control - Ambulanceverpleegkundige/AVP_Inrichting_KruispuntP6_01_Dialoog1_Secundair.mp3';
import AVP_Inrichting_KruispuntP6_01_Dialoog1_Treatment from './Dialogen/TV Command & Control - Ambulanceverpleegkundige/AVP_Inrichting_KruispuntP6_01_Dialoog1_Treatment.mp3';
import AVP_Inrichting_KruispuntP6_01_Dialoog3_Eind from './Dialogen/TV Command & Control - Ambulanceverpleegkundige/AVP_Inrichting_KruispuntP6_01_Dialoog3_Eind.mp3';
import AVP_Methane_KruispuntP4_02_Dialoog1 from './Dialogen/TV Command & Control - Ambulanceverpleegkundige/AVP_Methane_ KruispuntP4_02_Dialoog1.mp3';
import AVP_schakelen_Ambulance06_Dialoog1_BijstandsgroepKiezen from './Dialogen/TV Command & Control - Ambulanceverpleegkundige/AVP_schakelen_Ambulance06_Dialoog1_BijstandsgroepKiezen.mp3';
import AVP_Transport_KruispuntP6_01_Dialoog1_Branche1 from './Dialogen/TV Command & Control - Ambulanceverpleegkundige/AVP_Transport_KruispuntP6_01_Dialoog1_Branche1.mp3';
import AVP_Transport_KruispuntP6_01_Dialoog1 from './Dialogen/TV Command & Control - Ambulanceverpleegkundige/AVP_Transport_KruispuntP6_01_Dialoog1.mp3';
import AVP_Veiligheid_OpstelplaatsP1_01_Dialoog4 from './Dialogen/TV Command & Control - Ambulanceverpleegkundige/AVP_Veiligheid_OpstelplaatsP1_01_Dialoog4.mp3';
//#endregion


export const soundlist = {
	portoClick,
	porto,

	//ACH
	ACH_Aanrijdend_Ambulance02_Dialoog2,
	ACH_Aanrijdend_Ambulance02_Dialoog3_HesjeKiezen_Karl,
	ACH_Aanrijdend_Ambulance04_BepaalRoute,
	ACH_Aanrijdend_Ambulance04_BepaalRoute2,
	ACH_Aanrijdend_Ambulance04_dialoog_0,
	ACH_Aanrijdende_Ambulance04_dialoog2,
	ACH_Indeling_Dialoog2,
	ACH_Indeling_Dialoog4,
	ACH_Indeling_Overzichtsschets_Opstelplaats_2,
	ACH_Indeling_Overzichtsschets_aan_en_afvoerroutes_2,
	ACH_Indeling_Overzichtsschets_aan_en_afvoerroutes_2_fout,
	ACH_Schakelen_Ambulance02_Dialoog1_Portofoon_GroepKiezen,
	Karl1,
	Karlfinish,

	//AVP
	AVP_Aanrijdend_Ambulance04_dialoog_0,
	AVP_Aanrijdend_Ambulance06_dialoog2,
	AVP_Aanrijdend_Ambulance06_dialoog3,
	AVP_Aanrijdend_Ambulance06_dialoog4,
	AVP_Inrichting_KruispuntP6_01_Dialoog1_Secundair,
	AVP_Inrichting_KruispuntP6_01_Dialoog1_Treatment,
	AVP_Inrichting_KruispuntP6_01_Dialoog3_Eind,
	AVP_Methane_KruispuntP4_02_Dialoog1,
	AVP_Transport_KruispuntP6_01_Dialoog1,
	AVP_Transport_KruispuntP6_01_Dialoog1_Branche1,
	AVP_Veiligheid_OpstelplaatsP1_01_Dialoog4,
	AVP_schakelen_Ambulance06_Dialoog1_BijstandsgroepKiezen,

	//tutorial
	tutorial01_dialoog1_01,
	tutorial01_uitleg02,
	tutorial01_uitleg_01,
	tutorialV2_Ambulance02_2_Karl,
	tutorialV2_Ambulance02_HotspotDialoog2,
	tutorialV2_Ambulance02_Karl,
	tutorialV2_Kruispunt_Dialoog3,
	tutorialV2_Kruispunt_Dialoog4,
	tutorialV2_Kruispunt_Dialoog5,
	tutorialV2_Kruispunt_Dialoog6,
	tutorialV2_Kruispunt_Dialoog7,

	//ambulance
	ambulance_01_01,
	ambulance_02_01,
	ambulance_03_01,
	ambulance_04_01,
	ambulance_05_01,
	ambulance_06_01,
	ambulance_07_01,
	ambulance_08_01,

	//transport
	transport_Ambulancebijstandsplan_Meldkamer_P1_01_Dialoog1,
	transport_Ambulancebijstandsplan_Meldkamer_P1_01_Dialoog1_OptieBijstand_Feedback,
	transport_Ambulancebijstandsplan_Meldkamer_P1_01_Dialoog2,
	transport_Ambulancebijstandsplan_Meldkamer_P1_01_Dialoog3,
	transport_Ambulancebijstandsplan_Meldkamer_P1_01_Dialoog4,
	transport_Ambulancebijstandsplan_Meldkamer_P1_01_Dialoog6,
	transport_Ambulancebijstandsplan_Meldkamer_P1_01_Dialoog8f,
	transport_Ambulancebijstandsplan_Meldkamer_P1_01_Dialoog9,
	transport_Gewondenspreiding_Meldkamer_P1_01_Dialoog12_Eind,
	transport_Melding_Meldkamer_P1_01_Dialoog1,
	transport_Melding_Meldkamer_P1_01_Dialoog20,
	transport_Transport_Meldkamer_P1_01_Dialoog1,
	transport_Transport_Meldkamer_P1_01_Dialoog2,
	transport_Verbindingen_Meldkamer_P1_01_Dialoog1,
	transport_Verbindingen_Meldkamer_P1_01_Dialoog2,
	transport_Verbindingen_Meldkamer_P1_01_Dialoog3,

	//triage
	triage_aanrijdend_ambulance_05_dialoog3_hesjekiezen_karl,
	triage_aanrijdend_ambulance_05_spullenverzamelen,
	triage_schakelen_ambulance_05_dialoog2_portofoon_groepkiezen,
	triage_triage_terras_p1_03_dialoog3,
	triage_triage_terras_p1_03_dialoog3a,
	triage_uitvoeren_opstelplaats_p1_03_dialoog1,
	triage_uitvoeren_opstelplaats_p1_03_dialoog2,
	triage_uitvoeren_opstelplaats_p1_03_dialoog4,
	triage_uitvoeren_opstelplaats_p1_03_dialoog5,

	//treatment
	treatment_aanrijdend_ambulance_05_hesjekiezen,
	treatment_organiseren_hena_p1_02_2_dialoog2,
	treatment_schakelen_ambulance_05_dialoog2_portofoon_groepkiezen,
	treatment_taken_hena_p2_01_dialoog1,
	treatment_taken_hena_p2_01_dialoog4,
	treatment_transport_HENA_P1_03_dialoog2,
	treatment_transport_HENA_P1_03_dialoog2a,

	//secundaire triage
	secundair_Registratie_sporthal_p2_01_dialoog2a,
	secundair_Schakelen_Ambulance_05_Dialoog2_portofoon_groepkiezen,
	secundair_organiseren_opstelplaats_p2_04_dialoog,
	secundair_registratie_sporthal_p2_01_dialoog2,

	//hena
	hena_P1_01_01,
	hena_P1_02_01,
	hena_P1_03_01,
	hena_P2_01_01,
	hena_P2_02_01,
	hena_P2_03_01,

	//kruispunt
	kruispunt_P1_01_01,
	kruispunt_P1_02_01,
	kruispunt_P2_01_01,
	kruispunt_P2_02_01,
	kruispunt_P3_01_01,
	kruispunt_P3_02_01,
	kruispunt_P4_01_01,
	kruispunt_P4_02_01,
	kruispunt_P5_01_01,
	kruispunt_P5_02_01,
	kruispunt_P6_01_01,
	kruispunt_P6_02_01,
	kruispunt_P7_01_01,
	kruispunt_P7_02_01,
	kruispunt_P8_01_01,
	
	//loodspost
	loodspost_01_01,
	loodspost_02_01,
	loodspost_03_01,
	loodspost_04_01,
	loodspost_Aanrijdend_Ambulance08_Dialoog1_Hesjes,
	loodspost_Schakelen_ambulance08_Dialoog4,
	loodspost_Transport_Loodspost_03_Dialoog2_Eind,
	loodspost_Transport_Loodspost_03_Dialoog2a_Eind,
	loodspost_informeren_loodspost02_dialoog1,

	//meldkamer
	meldkamer_P1_01_01,

	//opstelplaats
	opstelplaats_P1_01_01,
	opstelplaats_P1_02_01,
	opstelplaats_P1_03_01,
	opstelplaats_P1_04_01,
	opstelplaats_P2_01_01,
	opstelplaats_P2_02_01,
	opstelplaats_P2_03_01,
	opstelplaats_P2_04_01,

	//ovdg
	OVDG_P1_01_01,
	OVDG_P2_01_01,
	OvDG_Aanrijdend_Kruispunt_P6_01_Dialoog1,
	OvDG_Aanrijdend_OvDG_P2_01_Dialoog2,
	OvDG_Aanrijdend_OvDG_P2_01_Dialoog2_Inzet,
	OvDG_Coördineren_Kruispunt_P3_01_Dialoog6,
	OvDG_Indeling1_OpstelplaatsP1_02_Dialoog3b,
	OvDG_TweedeOvDG_OpstelplaatsP1_03_Dialoog2_Eind,
	OvDG_schakelen_OvDG_P1_01_Dialoog2,
	OvDG_schakelen_OvDG_P1_01_Dialoog4,
	OVDG_Aanrijdend_P2_01_dialoog_0,

	// snelweg
	snelweg_P1_01_01,

	// sporthal
	sporthal_P1_01_01,
	sporthal_P1_02_01,
	sporthal_P2_01_01,
	sporthal_P2_02_01,
	sporthal_P3_01_01,
	sporthal_P4_01_01,

	// terras
	terras_P1_01_01,
	terras_P1_02_01,
	terras_P1_03_01,
	terras_P1_04_01

};
