import styled from 'styled-components';

const Button = styled.button`
	
	border: none;
	outline: none;
	cursor: pointer;
	user-select: none;
	margin:0;
	padding:0;

`;

export default Button;