import { useState } from 'react';
import styled from 'styled-components';
import { useGlobalContext } from '../../../context/global';


const Lives = () => {

	const {lives, takeLife, getFile} = useGlobalContext();

	const heartfile = getFile && getFile('eed6dd00-97f1-476b-a0e8-4df27cff3756');

	return (
		<Container>
			{lives && lives.map((life, index) => 
				<Life state={life} key={index} url={heartfile}/>
			)}
		</Container>
	);
};

//styled components
const Container = styled.div`
	box-sizing: border-box;
    background-color: ${p => p.theme.maincolors.GGBblue};
	display: flex;
	align-items: stretch;
	padding: 5px;
	> * + * {
		margin-left: 5px;
	}
	@media (max-width: ${p => p.theme.responsive.media.lg}){
		height: fit-content;
    }

    //desktop
	@media (min-width: ${p => p.theme.responsive.media.lg}){       
		width: 100%;
    }
`;

type LifeProps = {
	state: boolean,
	url: string | undefined,
}

const Life = styled.div<LifeProps>`
    background-image: url(${p => p.url});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;

	flex-grow: 1;

	opacity: ${p => p.state ? 1 : 0.3};

	@media (max-width: ${p => p.theme.responsive.media.lg}){
		width: 20px;
		height: 20px;
    }

    //desktop
	@media (min-width: ${p => p.theme.responsive.media.lg}){       
		height: 30px;
		/* width: 30px; */
    }
`;

export default Lives;