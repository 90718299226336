import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { isGeneratorFunction } from 'util/types';
import { soundlist } from '../../assets/sounds';
import { useAudioContext } from '../../context/audio';
import { useGlobalContext } from '../../context/global';
import { useTextContext } from '../../context/text/TextContext';
import { addDecisionItem, Option } from '../../data/decisions';
import { DialogueChunkData, DialogueOptionData } from '../../services/directus';
import Theme from '../../style/theme';
import BasicButton from '../Buttons/BasicButton';
import BasicFrame from '../Frames/BasicFrame';
import { Paragraph } from '../Typography';
import AdvancedDecisionComponent, { AnswerProps } from './AdvancedDecisionComponent';
import Avatar from './Avatar';
import { createAvatarList, createDialogueList, dialogueList, initializeData } from './DialogueData';
import DialogueImage from './DialogueImage';
import DialogueOptions from './DialogueOptions';

const Dialogue = () => {

	const { completeExternalLoadCheck, LoadScene, setShowDialogueImage, setDialogueImage, dialogueImage , addExternalLoadCheck, setGameState, activeDialogue, setActiveDialogue, startTimer, stopTimer, endTimeEarly, gameState} = useGlobalContext();
	const {t} = useTextContext();
	const {playSound, stopVo} = useAudioContext();

	//#region Initialization

	const initialize = async () => {

		const loadCheckId = addExternalLoadCheck && addExternalLoadCheck();

		await initializeData();

		createDialogueList();
		createAvatarList();

		completeExternalLoadCheck && loadCheckId !== undefined && completeExternalLoadCheck(loadCheckId);
		setInitialization(2);
	};

	const [ initialization, setInitialization ] = useState<number>(0); // 0 == not initialized, 1 == initializing, 2 == initialized

	useEffect(() => {
		
		if(initialization == 2 || initialization == 1)return;

		else if( initialization == 0 ){
			setInitialization(1);
			initialize();
		}

	},[]);

	useEffect(() => {
		activeDialogue && setDialogueChunk(dialogueList[activeDialogue]?.chunk);

		const sorted = activeDialogue && dialogueList[activeDialogue] && dialogueList[activeDialogue]?.options.sort((a, b) => {
			if(!a || !b || a.order == null || b.order == null) return 0;
			return a.order - b.order;
		});

		sorted && setSortedOptions(sorted);
		activeDialogue && setOptions(dialogueList[activeDialogue]?.options);

		//timer
		if(activeDialogue && dialogueList[activeDialogue]?.chunk.timer_time && (dialogueList[activeDialogue]?.chunk.enable_player_decision || dialogueList[activeDialogue]?.chunk.adc_enabled)){
			const time = dialogueList[activeDialogue]?.chunk.timer_time;
			if(time && setActiveDialogue)
				startTimer && startTimer(time);
		}
		//sound
		activeDialogue && dialogueList[activeDialogue]?.chunk.sound && playSound && playSound(soundlist[dialogueList[activeDialogue]?.chunk.sound]);
		if(activeDialogue && dialogueList[activeDialogue]?.chunk.voice_over){
			playSound && playSound(soundlist[dialogueList[activeDialogue]?.chunk.voice_over],null, true);
		} else {
			stopVo && stopVo();
		}
	}, [activeDialogue]);

	//#endregion

	// Proccess 
	
	const [dialogueChunk, setDialogueChunk] = useState<DialogueChunkData | undefined>();
	const [options, setOptions] = useState<DialogueOptionData[]>();
	const [sortedOptions, setSortedOptions] = useState<DialogueOptionData[]>();
	const [decisonOptions, setDecisionOptions] = useState<Option[]>();

	useEffect(() => {		
		const createOptions: Option[] = [];	
		options?.map((option) => {
			//key?
			createOptions.push({option: option.text, feedback: option.feedback_text});
		});
		setDecisionOptions(createOptions);
	}, [options]);

	useEffect(() => {
		if(dialogueChunk){
			if(dialogueChunk.image){
				setDialogueImage && setDialogueImage(dialogueChunk.image);
				window.matchMedia(`(max-width: ${Theme.responsive.media.lg})`).matches && setShowDialogueImage && setShowDialogueImage(true);
			}
			else{
				setDialogueImage && setDialogueImage(null);
			}

			if(dialogueChunk.adc_enabled){
				setSituation(4);
			}
			else if(dialogueChunk.enable_player_decision){
				setSituation(3);
			}
			else{
				setSituation(1);
			}
		}
		else{
			setDialogueImage && setDialogueImage(null);
			setSituation(0);
		}
	},[dialogueChunk]);

	const next = () => {		
		if(dialogueChunk?.swap_scene){
			LoadScene && LoadScene(dialogueChunk.swap_scene);
		}
		stopTimer && stopTimer();
		if(dialogueChunk?.follow_up_chunk){
			setActiveDialogue && setActiveDialogue(dialogueChunk.follow_up_chunk);
		}
		else{
			setActiveDialogue && setActiveDialogue('');
			stopVo && stopVo();
			setDialogueChunk(undefined);
			if(dialogueChunk?.end_game == true){
				setGameState && setGameState(3);
			}
		}
	};

	const answer = (a: number, adcOptions?:Option[]) => {
		stopTimer && stopTimer();
		if(dialogueChunk){	
			
			if(dialogueChunk.adc_enabled){
				// fill in a different decision item based on the given ADC

				// fill options with the correct one and if the player chose something else, the wrong one.
				// correct option equals 0 always for ADC
				// chosen option can be 0 if they chose the correct option
				// task
				addDecisionItem(
					{					
						description: dialogueChunk.adc_description ? dialogueChunk.adc_description : 'empty adc description',
						options: adcOptions && 0 === a  ? [adcOptions[0]] : adcOptions ? adcOptions : [],
						correct_option: 0,
						chosen_option: a,
						task: dialogueChunk.adc_task ? dialogueChunk.adc_task : 'empty adc task',
						adc: true
					});	

				if(a === 0 && dialogueChunk.adc_followup_correct){
					setActiveDialogue && setActiveDialogue(`${dialogueChunk.adc_followup_correct}`);
					return;
				}
				else if(dialogueChunk.adc_followup_wrong){
					setActiveDialogue && setActiveDialogue(`${dialogueChunk.adc_followup_wrong}`);
					return;
				}
			}
			else{
				addDecisionItem(
					{					
						description: dialogueChunk.decision_description ? dialogueChunk.decision_description : '',
						options: decisonOptions ? decisonOptions : [],
						correct_option: dialogueChunk.correct_decision ? dialogueChunk.correct_decision : 0,
						chosen_option: a ,
						task: dialogueChunk.task ? dialogueChunk.task : '',
						adc: false
					});
			}

		
			if(options && options[a]){

				//swap scene if there is one
				if(options[a].swap_pannellum_scene){
					const swap = options[a].swap_pannellum_scene;
					if(swap)
						LoadScene && LoadScene(swap);
				}

				//next chunk
				if(options[a].follow_up_chunk){
					setActiveDialogue && setActiveDialogue(`${options[a].follow_up_chunk}`);
					return;
				} else {
					stopVo && stopVo();
				}


			}
			next();
		}
	};

	const [situation, setSituation] = useState<number>(4);

	// 1 === dialogue with next button. Avatar always visible
	// 2 === dialogue with options(option not yet revealed). Avatar only visible on bigger screens (> 1000px of vw).
	// 3 === dialogue with options(options revealed). Avatar only visible on bigger screens (> 1000px of vw).

	return (  
		<> 
			<Container>
				{ activeDialogue && dialogueChunk && situation && gameState === 0 &&
				<InnerContainer>

					<Content>
						<TopRow>
							<Avatar avatarId={dialogueChunk.avatar} situation={situation}/>
							<DialogueImage dialogueChunk={dialogueChunk}/>
						</TopRow>
						{ (situation === 1 || situation === 2 || situation === 3 || situation === 4) &&
								<BasicFrame_Dialogue>
									<DialogueText>{t(dialogueChunk.text)}</DialogueText>
								</BasicFrame_Dialogue>
						}
						{ situation === 1 &&
								<Next onClick={() => next()}>
									{t('next')}
								</Next>
						}
						{ situation === 3 &&
								<DialogueOptions dialogueChunk={dialogueChunk} options={options} sortedOptions={sortedOptions} answer={(a:number) => answer(a)}/>
						}
						{ situation === 4 && 
								<AdvancedDecisionComponent dialogueChunk={dialogueChunk} answer={(ap:AnswerProps) => answer(ap.answer, ap.adcOptions)}/>
						}
					</Content>
				</InnerContainer>

				}

			</Container>
		</>
	);
};

// key frames

// styled components

const Container = styled.div`

	flex-grow: 1;
	min-height: 0;
	/* max-height: 0; */
	order: 1;
	width: 100%;

	position: relative;

	/* max-height: 100px; */
	/* height: 100%; */

	z-index: 2;
	box-sizing: border-box;

	@media (max-width: ${p => p.theme.responsive.media.lg}){
		font-size: 2vw;
    }

	@media (min-width: ${p => p.theme.responsive.media.lg}){
		font-size: 1.25vw;
    }

	user-select: none;

	& > * {
		pointer-events: all;
	}
`;

const InnerContainer = styled.div`
	position: absolute;
	left:0;top:0;bottom:0;right:0;
	overflow-y: auto;

	@media (max-width: ${p => p.theme.responsive.media.lg}){
		padding-bottom: ${p => p.theme.responsive.padding.sm}px;
    }

	@media (min-width: ${p => p.theme.responsive.media.lg}){
		padding-left:${p => p.theme.responsive.padding.lg}px;
    }
`;

const Content = styled.div`
	display: flex; 
	flex-direction: column; 
	align-items: center;
	justify-content: flex-end;

	min-height: 100%;
`;

const BasicFrame_Dialogue = styled(BasicFrame)`

	display: flex;
	text-overflow: ellipsis;
	/* white-space: nowrap; */
	overflow: hidden;
	width: 100%;
	box-sizing: border-box;


	& > p {
		display: flex;
		text-align: center;
		align-items: center;
	}

	// smaller screens

	@media (max-width: ${p => p.theme.responsive.media.lg}){
		/* height: 7em; */
		margin-bottom: ${p => p.theme.responsive.padding.sm / 4}px;
    }

	// bigger screens

	@media (min-width: ${p => p.theme.responsive.media.lg}){
		/* height: 5em; */
		margin-bottom: ${p => p.theme.responsive.padding.lg / 4}px;

    }

`;

const DialogueText = styled(Paragraph)`
	margin: auto;
	text-align: center;
`;

const Next = styled(BasicButton)`
	/* margin-bottom: 25px; */
`;



const TopRow = styled.div`

	position: relative;

	display: flex;
	width: 100%;

	/* align-items: flex-end; */

	@media (max-width: ${p => p.theme.responsive.media.lg}){
		height:1rem;
    }

	@media (min-width: ${p => p.theme.responsive.media.lg}){
		flex-grow: 1;
    }

`;
 
export default Dialogue;
