
import { ID } from '@directus/sdk';
import { getConfig } from '@testing-library/dom';
import { useEffect, useState } from 'react';
import ReactPannellum, {isLoaded, getConfig as gf} from 'react-pannellum';
import { dumyRender } from '../../assets/images';
import { soundlist } from '../../assets/sounds';
import { useAudioContext } from '../../context/audio';
import { useGlobalContext } from '../../context/global';
import { useTextContext } from '../../context/text/TextContext';
import { addDecisionItem, DecisionData } from '../../data/decisions';
import { scenarioData, initializeData as initializeScenarioData, scenarioDataAvailable } from '../../data/scenarioData';
import { ConsoleRow } from '../Console/components';
import PannellumConfig from './PannellumConfig';
import { createScenelist, initializeData, SceneList } from './PannellumData';

const Pannellum  = () => {

	// context

	const {setActiveDialogue, activePannellumScene, setActivePortoDialogue, setShowCards, showCards, startTimer, stopTimer, LoadScene, completeExternalLoadCheck, addExternalLoadCheck, activeDialogue} = useGlobalContext();
	const {playSound, playBackgroundSound} = useAudioContext();
	// useStates

	const [ config, setConfig ] = useState<typeof PannellumConfig | undefined>();

	const [ scenelist, setScenelist ] = useState<SceneList | undefined>();

	const [ initialization, setInitialization ] = useState<number>(0); // 0 == not initialized, 1 == initializing, 2 == initialized
	
	// functions
	const OnHotspotClick = (type: string | undefined, decision: DecisionData | undefined, pannellum: string | undefined, dialogue: string | undefined, overlay: number | undefined) => {
		switch(type){
		case 'navigate':
			LoadScene && LoadScene(pannellum ? pannellum : '');
			break;
		case 'dialogue':
			if(dialogue)
				setActiveDialogue && setActiveDialogue(dialogue);		
			break;
		case 'overlay':
			//
			break;
		}			
		stopTimer && stopTimer();
		if(decision && decision.options.length != 0){	
			addDecisionItem(decision);
		}
	};

	// methods
	// const loadFirstScene = async () => {
	// 	if(scenarioDataAvailable())		
	// 		LoadScene && scenarioData && LoadScene(scenarioData[0].starting_scene ? scenarioData[0].starting_scene : '');
	// 	return await new Promise( resolve => {
	// 		const interval = setInterval(()=>{
	// 			if(isLoaded()){
	// 				resolve('foo');
	// 				clearInterval(interval);
	// 			}	
	// 		}, 100);
	// 	});
	// };

	const initialize = async () => {

		const loadCheckId = addExternalLoadCheck && addExternalLoadCheck();

		setConfig({...PannellumConfig});
		

		await initializeScenarioData();

		await initializeData();

		await createScenelist(OnHotspotClick)
			.then((a: SceneList) => {
				setScenelist(a);
			});

		//await loadFirstScene();
	
		completeExternalLoadCheck && loadCheckId !== undefined && completeExternalLoadCheck(loadCheckId),
		setInitialization(2);
	};

	// useEffects

	useEffect(() => {

		if(initialization == 2 || initialization == 1)return;

		else if( initialization == 0 ){
			setInitialization(1);
			initialize();

		}
		
	},[]);



	const [shownDialogues, setShownDialogues] = useState<string[]>([]);
	useEffect(() => {
		if(scenelist && activePannellumScene){
			//sound
			scenelist[activePannellumScene]?.sceneData.sound && playSound && playSound(soundlist[scenelist[activePannellumScene]?.sceneData.sound]);
			scenelist[activePannellumScene]?.sceneData.background_sound && playBackgroundSound && playBackgroundSound(true, soundlist[scenelist[activePannellumScene]?.sceneData.background_sound]);
			//dialogue		
			if(shownDialogues.includes(activePannellumScene)){
				return;
			} else {	
				const dialogue = scenelist[activePannellumScene]?.sceneData.starting_dialogue; 
				if(dialogue){
					setActiveDialogue && setActiveDialogue(dialogue);
					shownDialogues.push(activePannellumScene);
				}
			}
			//timer
			if(scenelist[activePannellumScene]?.sceneData.decision_group_toggle){
				startTimer && startTimer(30000);
			}
			//porto
			if(scenelist[activePannellumScene]?.sceneData.porto_toggle){
				if(showCards)
					setShowCards && setShowCards([showCards[0], showCards[1], showCards[2], true]);
				const portoDialogue = scenelist[activePannellumScene]?.sceneData.porto_dialogue;
				if(portoDialogue)
					setActivePortoDialogue && setActivePortoDialogue(portoDialogue);
			}
		}
	},[activePannellumScene]);

	// render
	
	return (  
		<> 
			{ config && 
			<>
				<ReactPannellum
					id='-1'
					sceneId='-1'
					imageSource={dumyRender}
					config={config}
					style={{
						position: 'absolute',
						left: '0',
						top: '0',
						width: '100%',
						height: '100%',
						background: '#000000',
						zIndex: '1',
						pointerEvents: activeDialogue ? 'none' : 'all'
					}}
				/>
			</>
			}
		</>
	);

};

// styled components

// data



// types


 
export default Pannellum;
