import { useGlobalContext } from '../../../context/global';
import { ConsoleLabel, ConsoleRow } from '.';

const Toggles = () => {

	const {takeLife, endTimeEarly, startTimer, setActiveDialogue,setTimerToggle, timerToggle, setShowCards, showCards} = useGlobalContext(); 
	
	return(
		<ConsoleRow>
			<ConsoleLabel>Some toggles and handy buttons:</ConsoleLabel>
			<button onClick={takeLife}>Take a life</button>
			<button onClick={endTimeEarly}>End timer early</button>
			<button onClick={() => setShowCards && showCards && setShowCards([showCards[0], showCards[1], showCards[2], !showCards[3]])}>Toggle Porto button</button>
			<button onClick={() => startTimer && startTimer(30000)}>timer test</button>
			<button onClick={() => setTimerToggle && setTimerToggle(!timerToggle)}>Set timer off/on</button> timer = {timerToggle ? 'on' : 'off'}
		</ConsoleRow>
	);
};

export default Toggles;