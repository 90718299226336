import { useState } from 'react';
import styled from 'styled-components';
import { useAudioContext } from '../../context/audio';
import { useGlobalContext } from '../../context/global';

interface MuteProps {
    url: string | undefined
    hoverfile: string
}

const MuteButton = styled.button<MuteProps>` 
	position: absolute;

    //mobile
	@media (max-width: ${p => p.theme.responsive.media.lg}){
		margin-top: 10px;
		margin-right: 25px;
		top: 0;
		right: 0;
    };

    //desktop
	@media (min-width: ${p => p.theme.responsive.media.lg}){	
		margin: 20px;
		right:0;
		bottom:0;
    };
	
	height: 40px;
	width: 40px;


    background-image: url(${p => p.url});
	background-size: 60%;
	background-position: center;
	background-color: white;
	background-repeat: no-repeat;

	//opacity: 0.25;
	border: none;

	z-index: 2;

	&:hover{
        opacity: 1;
        cursor:pointer;
	}
`;

const Mutebutton = () => {

	const {voSource, audioSources, backgroundSource, setMuted} = useAudioContext();
	const {getFile} = useGlobalContext();
	const [muteState, setMuteState] = useState<boolean>(false);

	const mute = () => {
		setMuted && setMuted(true);
		setMuteState(true);
		if(backgroundSource && voSource && audioSources){
			backgroundSource.muted = true;
			voSource.muted = true;
			audioSources.map((audioSource, index) => {
				audioSource.muted = true;
			});
		}
	};

	const soundon = () => {
		setMuted && setMuted(false);
		setMuteState(false); 
		if(backgroundSource && voSource && audioSources){
			backgroundSource.muted = false;
			voSource.muted = false;
			audioSources.map((audioSource, index) => {
				audioSource.muted = false;
			});		}

	};
	return (
		<>
			{muteState ? 
				<MuteButton onClick={soundon} url={getFile && getFile('8430fbf6-fd9c-4bf1-a121-20f72994c6fa')}/> 
				:
				<MuteButton onClick={mute} url={getFile && getFile('513dfd3c-b73f-4146-a5ff-466567015413')}/>
			}
		</>
	);

};

export default Mutebutton;