import styled from 'styled-components';

const ConsoleRow = styled.div`
    display: flex;
    justify-content: left;
	text-align: left;
    align-items: center;
	margin-top: 10px;
    button {
        margin-left: 30px;
    }
	select {
		font-size: 24px;
	}
`;

const ConsoleLabel = styled.div`
	font-size: 1.2rem;
	font-weight: bold;
	padding-right: 10px;
`;

export{ConsoleRow, ConsoleLabel};
