import { ReactNode } from 'react';
import styled from 'styled-components';
import { fadeInHorizontal, fadeInVertical, fadeOutHorizontal, fadeOutVertical } from '../animations';

const Card = (props: {children? : ReactNode, onClick?: () => void, url?: string | undefined, state: boolean}) => {

	return (
		<CardBox state={props.state} url={props.url} onClick={props.onClick}/>
	);
};

//styled components


const CardBox = styled.div<{url: string | undefined, state: boolean}>`
    background-image: url(${p => p.url});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	opacity: none;
	height: 100%;
	width: 100%;
	cursor: pointer;

	@media (max-width: ${p => p.theme.responsive.media.lg}){
		animation: ${p => p.state? fadeInVertical : fadeOutVertical } 2s forwards;
    }

    //desktop
	@media (min-width: ${p => p.theme.responsive.media.lg}){
		animation: ${p => p.state? fadeInHorizontal : fadeOutHorizontal } 2s forwards;

    }
	animation-delay: 1.2s;
`;

export default Card;