import { useState } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
import { AdvancedDecisionProps } from '.';
import { useGlobalContext } from '../../../context/global';
import { useTextContext } from '../../../context/text/TextContext';
import BasicButton from '../../Buttons/BasicButton';
import OptionButton from '../../Buttons/OptionButton';
import { Paragraph } from '../../Typography';
import OptionsContainer from '../components/OptionsContainer';

const HesjeKiezen = (props: AdvancedDecisionProps) => {

	const [selected, setSelected] = useState<number | undefined>();

	const { getFile } = useGlobalContext();
	const { t } = useTextContext();


	const images = [
		getFile && getFile('e7bc845b-424b-493c-b9a6-14ace56e1f9c'),
		getFile && getFile('6d1edc22-e797-4ead-9361-c53d423f241e'),
		getFile && getFile('b60e8bbf-434d-4d9e-9327-bd398e10b189'),
		getFile && getFile('c0881518-738f-4dd1-b2ac-e5e1044db54b'),
	];

	const submitAnswer = () => {
		let answer = '';

		answer = t(`dialogue-adc1-label-${selected}`);

		props.answer({
			answer: answer === t(props.dialogueChunk.adc_correct) ? 0 : 1,
			adcOptions: [
				{
					option: props.dialogueChunk.adc_correct,
					feedback: props.dialogueChunk.adc_feedback_correct,
				},
				{
					option: answer,
					feedback: props.dialogueChunk.adc_feedback_wrong,
				}
			]
		});
	};

	return(
		<Container>
			<StyledOptionsContainer singleRow={true} style={{flexGrow: '1'}}>
				{[...Array(4)].map((value, index) => 
					<Option key={`hesjes-${index}`} onClick={() => setSelected(index)} selected={selected === index ? true : false}>
						<Hesje url={images[index]}/>
						<OptionLabel>{t(`dialogue-adc1-label-${index}`)}</OptionLabel>
					</Option>
				)}
			</StyledOptionsContainer>
			<div style={{margin: 'auto auto 0 auto'}}><BasicButton disabled={selected === undefined ? true: false} onClick={() => selected !== undefined && submitAnswer()}>Bevestig</BasicButton></div>		
		</Container>
	);
};

// styled components

const StyledOptionsContainer = styled(OptionsContainer)`
	
	// smaller screens

	@media (max-width: ${p => p.theme.responsive.media.lg}){
		margin-bottom: ${p => p.theme.responsive.padding.sm / 4}px;
    }

	// bigger screens

	@media (min-width: ${p => p.theme.responsive.media.lg}){
		margin-bottom: ${p => p.theme.responsive.padding.lg / 4}px;
    }
`;

const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const Option = styled(OptionButton)<{selected:boolean}>`

	position: relative;

	display: flex;
	flex-direction: column;

	width: 100%;
	padding: 5px;
	
	${p => p.selected && css`
	outline:solid 2px ${p => p.theme.maincolors.GGBblue}!important;
	background-color: rgba(255,255,255,0.7);
	`}

`;

const Hesje = styled.div<{url: string | undefined}>`

	/* position: absolute;
	left:5px;top:5px;right:5px;bottom:5px; */
	flex-grow: 1;
	width: 100%;

	box-sizing: border-box;

    background-image: url(${p => p.url});
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;

`;

const OptionLabel = styled(Paragraph)`
	/* font-weight: bold; */
	margin: auto;
`;

export default HesjeKiezen;