import styled from 'styled-components';
import Lives from './Lives';
import Card from './Card';
import { useGlobalContext } from '../../context/global';
import Timer from './Timer';
import { fadeInHorizontal, fadeInVertical, fadeOutHorizontal, fadeOutVertical } from './animations';
import { useEffect } from 'react';
import { useAudioContext } from '../../context/audio';
import { soundlist } from '../../assets/sounds';

const ButtonInterface = () => {

	const {setShowTaskCard, showCards, activePortoDialogue, gameState, setShowCards, setActiveDialogue, setShowDialogueImage, dialogueImage} = useGlobalContext();
	const {getFile} = useGlobalContext();
	const {playSound} = useAudioContext();
	const taskCard = getFile && getFile('88fec91a-d17e-43a0-8beb-7b230b2662f2');
	const portoCard = getFile && getFile('00a83d3b-b6ae-4338-9644-b1a68d38172f');
	const camera = getFile && getFile('5dda8c3f-f689-4376-ae20-48baf82d262f');

	//taskcard functionality
	const taskcardFunction = () => {
		setShowTaskCard && setShowTaskCard(true);	
	};

	//Porto functionality
	const portoFunction = () => {
		playSound && playSound(soundlist['portoClick']);
		if(showCards && activePortoDialogue){
			setShowCards && setShowCards([showCards[0], showCards[1], showCards[2], false]);
			setActiveDialogue && setActiveDialogue(activePortoDialogue);
		}
	};

	useEffect(() => {
		if(showCards && showCards[3] == true){
			playSound && playSound(soundlist['porto']);
		}
	},[showCards && showCards[3]]);

	return (  
		<> 
			{showCards && gameState === 0 && 
			<Container>
				{showCards[0] && <Lives/>}				
				<CardBox state={showCards[1]} style={{backgroundColor: '#12439B'}}>
					<Timer/>
				</CardBox>	
				<CardBox state={showCards[2]}>
					<Card state={showCards[2]} onClick={taskcardFunction} url={taskCard}/>				
				</CardBox>
				<CardBox state={showCards[3]}>
					<Card state={showCards[3]} onClick={portoFunction} url={portoCard}/>
				</CardBox>
				<CardBox state={dialogueImage}>
					<Card state={dialogueImage ? true : false} onClick={() => dialogueImage && setShowDialogueImage && setShowDialogueImage(true)} url={camera}/>
				</CardBox>
			</Container>
			}
		</>
	);
};

// styled components

const Container = styled.div`
	& > * {
		pointer-events: all;
	}

	flex-shrink: 0;
	
	display: flex;

	z-index: 2;
	user-select: none;
	overflow: hidden;
	box-sizing: border-box;
	
    //mobile
	@media (max-width: ${p => p.theme.responsive.media.lg}){
		height: 65px;
        flex-direction: row;
		order: 0;
		> * + * {
		margin-left: 10px;
		}
    };

    //desktop
	@media (min-width: ${p => p.theme.responsive.media.lg}){
		width: 100px;
        flex-direction: column;
		align-items: flex-end;

		order: 2;

		> * + * {
		margin-top: 10px;
		}

		& > :nth-child(5){
			display: none;
		}
    };
`;

const CardBox = styled.div<{state: boolean}>`
    background-color: rgba(155, 154, 154, 0.5);
	display: flex;
	box-sizing: border-box;
	padding: 5px;

	//mobile
	@media (max-width: ${p => p.theme.responsive.media.lg}){
		height: 65px;
		width: 65px;
		animation: ${p => p.state? fadeInVertical : fadeOutVertical } 2s forwards;
    };

    //desktop
	@media (min-width: ${p => p.theme.responsive.media.lg}){       
		height: 100px;
		width: 100px;
		animation: ${p => p.state? fadeInHorizontal : fadeOutHorizontal } 2s forwards;
	};
`;
 
export default ButtonInterface;
