import { ReactNode } from 'react';
import styled from 'styled-components';
import Theme from '../../style/theme';

const InterfaceSpace = (props: {children: ReactNode}) => {

	return(
		<Container>
			{props.children}
		</Container>
	);
};

// styled components

const Container = styled.div`

    pointer-events: none;
    background: none;

    box-sizing: border-box;

    height: 100%;
    width: 100%;

    display: flex;

    position: relative;
	z-index: 2;

    //mobile
	@media (max-width: ${p => p.theme.responsive.media.lg}){
        flex-direction: column;
        padding: 0 ${Theme.responsive.padding.sm}px;
		> * + * {
		margin-bottom: 20px;
		}    
    };

    //desktop
	@media (min-width: ${p => p.theme.responsive.media.lg}){
        flex-direction: row;
        padding: ${Theme.responsive.padding.lg}px 0;
		> * + * {
			margin-left: 20px;
		}    
    };
`;
export default InterfaceSpace;
