import { useEffect, useRef, useState } from 'react';
import { useGlobalContext } from '../../../context/global';
import { ConsoleLabel, ConsoleRow } from '.';
import { scenelist } from '../../Pannellum/PannellumData';

const ConsoleSceneSelect = () => {

	const {LoadScene, activePannellumScene} = useGlobalContext();


	return(
		<ConsoleRow>
			<ConsoleLabel>active pannellum scene</ConsoleLabel>
			<select onChange={(e) => {LoadScene && LoadScene(e.target.value);}} value={activePannellumScene}>
				<option label={'none'} value={'-1'}/>
				{ scenelist && Object.keys(scenelist).map((key, index) => (
					<option key={`${index}-${key}-consolepentry`} label={ scenelist && scenelist[key]?.sceneData.scene_id.toString()} value={ scenelist && scenelist[key]?.sceneData.scene_id}/>))
				}
			</select>
		</ConsoleRow>
	);
};

export default ConsoleSceneSelect;