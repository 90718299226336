import { useEffect } from 'react';
import styled from 'styled-components';
import { useGlobalContext } from '../../context/global';
import { DialogueChunkData } from '../../services/directus';

const DialogueImage = (props:{dialogueChunk:DialogueChunkData}) => {

	const { getFile, setShowDialogueImage } = useGlobalContext();

	const file = getFile && props.dialogueChunk.image && getFile(props.dialogueChunk.image);

	return(
		<>
			{ file &&
				<Container onClick={() => setShowDialogueImage && setShowDialogueImage(true)} url={file}/>
			}
		</>
	);
};

// styled components

const Container = styled.div<{url: string | undefined}>`
	width: 70%;
	margin-left: auto;

    background-image: url(${p => p.url});
	background-repeat: no-repeat;
	background-size: contain;
	background-position: bottom right;
	
	@media (max-width: ${p => p.theme.responsive.media.lg}){
		display: none;
    }

	// bigger screens

	@media (min-width: ${p => p.theme.responsive.media.lg}){
		margin-bottom: ${p => p.theme.responsive.padding.lg / 2}px;	
    }

	cursor: pointer;

`;

export default DialogueImage;
