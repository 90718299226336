/* eslint-disable no-mixed-spaces-and-tabs */
import { useEffect } from 'react';
import styled from 'styled-components';
import { useGlobalContext } from '../../context/global';
import CloseButton from '../Buttons/CloseButton';

const DialogueImageMobile = () => {

	const { getFile, dialogueImage, setShowDialogueImage, showDialogueImage } = useGlobalContext();

	const file = getFile && dialogueImage && getFile(dialogueImage);

	useEffect(() => {
		if(!file){
			setShowDialogueImage && setShowDialogueImage(false);
		}
	},[file]);

	return (
		<>
			{ file && showDialogueImage &&
                <Container>
                	<Image url={file}></Image>
                	<Close><CloseButton onClick={() => setShowDialogueImage && setShowDialogueImage(false)}/></Close>
                </Container>
			}
		</>
	);
};

// styled components

const Container = styled.div`

    position: absolute;
    left:0;top:0;
    box-sizing: border-box;

    z-index: 3;

    display: flex;

    background: rgb(0,0,0,.75);

	height: 100%;
	width: 100%;

    @media (max-width: ${p => p.theme.responsive.media.lg}){
		padding: ${p => p.theme.responsive.padding.sm}px;
    }

	@media (min-width: ${p => p.theme.responsive.media.lg}){
		padding: ${p => p.theme.responsive.padding.lg}px;
    }
`;

const Image = styled.div<{url: string | undefined}>`
    background: pink;
    width: 100%;
	margin-right: ${p => p.theme.responsive.padding.sm/2}px;

    background-image: url(${p => p.url});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	background-color: white;

`;

const Close = styled.div`
`;

export default DialogueImageMobile;