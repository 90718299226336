import styled from 'styled-components';
import { soundlist } from '../../../assets/sounds';
import { useAudioContext } from '../../../context/audio';
import { useGlobalContext } from '../../../context/global';
import { useTextContext } from '../../../context/text/TextContext';
import { scenarioData, scenarioDataAvailable } from '../../../data/scenarioData';
import BasicButton from '../../Buttons/BasicButton';
import BasicFrame from '../../Frames/BasicFrame';
import { Heading, Paragraph } from '../../Typography';
import Container from '../components/Container';


const IntroductionScreen = () => {

	const {gameState, LoadScene, setGameState, getFile} = useGlobalContext();
	const {t} = useTextContext();
	const {playBackgroundSound} = useAudioContext();
	const background = getFile && getFile('e881647a-4959-4c99-823a-5c8ce98f8ec2');

	const startGame = () => {		
		setGameState && setGameState(0);
		if(scenarioData){
			LoadScene && LoadScene(scenarioData[0].starting_scene ? scenarioData[0].starting_scene : '');
			playBackgroundSound && playBackgroundSound(true, soundlist[scenarioData[0].sound]);
		}
	};
	return (  
		<Container>
			<StyledFrame>
				<Title>		
					<StyledHeading>Het incident</StyledHeading>
				</Title>
				<ImageBox url={background}/>
				<TextBox>
					<TextBoxBackdrop/>	
					<TextBoxContent>
						<Paragraph>{t('introductie1')}</Paragraph>
					</TextBoxContent> 					
				</TextBox>
			</StyledFrame>
			<ButtonRow>
				<BasicButton onClick={startGame}>Start spel</BasicButton>
			</ButtonRow>
		</Container>
	);
};

// styled components
const ButtonRow = styled.div`	
	
	display: flex;
	width: 100%;
	justify-content: space-around;

`;

const StyledHeading = styled(Heading)`
	@media (max-width: ${p => p.theme.responsive.media.lg}){
		margin-bottom: ${p => p.theme.responsive.padding.sm /2}px;

    }
	@media (min-width: ${p => p.theme.responsive.media.lg}){
		margin-bottom: ${p => p.theme.responsive.padding.lg /2}px;
    }
`;

const StyledFrame = styled(BasicFrame)`
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: 100%;
	text-align: center;
	padding:0;
	background: 0;
	min-height: 0;
	opacity: 1;
	//mobile
	@media (max-width: ${p => p.theme.responsive.media.lg}){
		
	}

    //desktop
	@media (min-width: ${p => p.theme.responsive.media.lg}){
		flex-grow: 1;
    }
`;

const TextBox = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	text-align: center;


	width: 100%;
	min-height: 0;
	box-sizing: border-box;

	//mobile
	@media (max-width: ${p => p.theme.responsive.media.lg}){
		padding: ${p => p.theme.responsive.padding.sm / 2}px ${p => p.theme.responsive.padding.sm}px;
		padding-bottom: ${p => p.theme.responsive.padding.sm / 2}px;
		padding-top: 0;
	}

	//desktop
	@media (min-width: ${p => p.theme.responsive.media.lg}){
		padding: ${p => p.theme.responsive.padding.lg / 2}px ${p => p.theme.responsive.padding.sm}px;
		padding-top: 0;
	}
`;

const TextBoxBackdrop = styled.div`
	background: rgba(155,155,155, 0.7);

	backdrop-filter: blur(10px);
	position: absolute;
	left:0;top:0;right:0;bottom:0;
	z-index: -1;
`;

const TextBoxContent = styled.div`
	overflow-y: auto;
	max-height: 100%;
	box-sizing: border-box;
	
	@media (max-width: ${p => p.theme.responsive.media.lg}){
		padding: ${p => p.theme.responsive.padding.sm / 2}px 0;

    }
	@media (min-width: ${p => p.theme.responsive.media.lg}){
		padding: ${p => p.theme.responsive.padding.lg / 2}px 0;
    }
`;

const Title = styled.div`

		background-color: rgba(18, 67, 155, 0.9);

		@media (max-width: ${p => p.theme.responsive.media.lg}){
			padding: ${p => p.theme.responsive.padding.sm}px;
			padding-bottom: 0;
    	}
		@media (min-width: ${p => p.theme.responsive.media.lg}){
			padding: ${p => p.theme.responsive.padding.lg}px;
			padding-bottom: 0;
		}

`;

const ImageBox = styled.div<{url: string | undefined}>`
    background-image: url(${p => p.url});
	background-size: cover;
	background-position: center;
	min-height: 150px;
	flex-grow: 1;

	//mobile
	@media (max-width: ${p => p.theme.responsive.media.lg}){
		display: none;
    }

    /* //desktop
	@media (min-width: ${p => p.theme.responsive.media.lg}){
    } */
`;

export default IntroductionScreen;
