import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { text } from 'stream/consumers';
import { directus } from '../../services/directus';
import { useGlobalContext } from '../global';
import { textDataRecord } from './types';

const TextContext = createContext<{t: (key:string) => string, data: textDataRecord | undefined}>({t: (key) => {return('data not loaded');}, data: {}});
const language = 'nl-NL';

const TextContextProvider = (props: {children: ReactNode}) => {
	
	const {addExternalLoadCheck, completeExternalLoadCheck} = useGlobalContext();

	const [data, setData] = useState<undefined | textDataRecord>();

	useEffect(() => {
		initialize();
	},[]);

	const initialize = async() => {

		const loadCheckId = addExternalLoadCheck && addExternalLoadCheck();
		const textsObject: textDataRecord = {};

		await directus.items('texts_translations').readMany({
			filter:{languages_code:{_eq: language}},
			limit: -1
		}).then((a) => {
			a.data && a.data.map((text) => {
				text.texts_key && text.text ? textsObject[text.texts_key] = text.text : '';
			});
		});
		setData(textsObject);
		completeExternalLoadCheck && loadCheckId != undefined && completeExternalLoadCheck(loadCheckId);

	};

	const t = (key: string): string => {
		if(data){
			if(key in data){
				return(data[key]);
			}
			else{
				console.error(`key '${key}' is not present in text data`);
				return(`invalid key: ${key}`);
			}
		}
		else{
			console.error('data requested from textContext while data is undefined');
			return('koekjes');
		}
	};

	return (
		<>	
			<TextContext.Provider value={{t, data}}>
				{props.children}
			</TextContext.Provider>
		</>

	);

};

const useTextContext = () => useContext(TextContext);


export {TextContext, TextContextProvider, useTextContext};
