import styled from 'styled-components';
import { css } from 'styled-components';

// styled components

const OptionsContainer = styled.div`
	position: relative;
	background-color: rgba(151, 151, 151, 0.5);
	backdrop-filter: blur(4px);
	display: flex;
	width: calc(100%);
	min-height: 0;

	overflow: hidden;

	box-sizing: border-box;

	@media (max-width: ${p => p.theme.responsive.media.lg}){
		padding: ${p => p.theme.responsive.padding.sm / 4}px;
		> * + * {
		margin-top: ${p => p.theme.responsive.padding.sm / 4}px;
		}
	}

	@media (min-width: ${p => p.theme.responsive.media.lg}){
		padding: ${p => p.theme.responsive.padding.lg / 6}px;
		> * + * {
		margin-top: ${p => p.theme.responsive.padding.lg / 6}px;
		}
    }

	${ p => p.singleRow ? css`
	> * + * {
		margin-left: ${p => p.theme.responsive.padding.lg / 6}px;
		margin-top: 0px;
	}
	align-items: stretch;

	` : css`
		
	flex-direction: column;

	`
}
`;


export default OptionsContainer;

