import { keyframes } from 'styled-components';

export const fadeInHorizontal = keyframes`
	0% {
		transform: translateX(90%);
	}

	100% {
		transform: translateX(0%);
	}
`;

export const fadeInVertical = keyframes`
	0% {
		transform: translateY(-90%);
	}

	100% {
		transform: translateY(0%);
	}
`;
export const fadeOutHorizontal = keyframes`
	0% {
		transform: translateX(0%);
	}

	100% {
		transform: translateX(90%);
	}
`;

export const fadeOutVertical = keyframes`
	0% {
		transform: translateY(0%);
	}

	100% {
		transform: translateY(-90%);
	}
`;