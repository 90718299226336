import { relative } from 'path';
import { useState } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
import { AdvancedDecisionProps } from '.';
import { useGlobalContext } from '../../../context/global';
import { useTextContext } from '../../../context/text/TextContext';
import BasicButton from '../../Buttons/BasicButton';
import OptionButton from '../../Buttons/OptionButton';
import { Paragraph } from '../../Typography';
import OptionsContainer from '../components/OptionsContainer';

const ItemSelectie = (props: AdvancedDecisionProps) => {

	const [toggleArray, setToggleArray] = useState<boolean[]>([false, false, false, false, false, false, false, false]);

	const { getFile } = useGlobalContext();
	const { t } = useTextContext();

	const images = [
		getFile && getFile('1c587ec5-9dc4-4efb-88d7-4dfade65f826'),
		getFile && getFile('d6e73c2d-b7a8-470a-816e-7231dc6e8f30'),
		getFile && getFile('e2219ab7-e9d3-4a84-806b-9af2e225e3fd'),
		getFile && getFile('35269b1e-d92c-4dd4-8e44-2187dacfdf32'),
		getFile && getFile('70af3d06-b945-487c-b04f-ed66719baee3'),
		getFile && getFile('888f1631-8676-4e6a-be7e-b8f939983d17'),
		getFile && getFile('a2cf6266-9ac1-4e17-9988-7d172c6d52ef'),
		getFile && getFile('711fa936-d8b5-4455-be93-0a503786fd7a'),
	];

	const submitAnswer = () => {
		let answer = '';
		toggleArray.map((value, index) => {
			if(value){
				answer += t(`dialogue-adc0-label-0${index + 1}`) + ',';
			}
		});
		answer = answer.slice(0, -1);
		props.answer({
			answer: answer === t(props.dialogueChunk.adc_correct) ? 0 : 1,
			adcOptions: [
				{
					option: props.dialogueChunk.adc_correct,
					feedback: props.dialogueChunk.adc_feedback_correct,
				},
				{
					option: answer,
					feedback: props.dialogueChunk.adc_feedback_wrong,
				}
			]
		});
	};

	return(
		<Container>
			<StyledOptionsContainer>
				<div style={{height: '100%', width: '100%', position: 'relative'}}>
					<div style={{position: 'absolute', inset: 5, }}>
						<OptionSubContainer>
							{[...Array(8)].map((value, index) => 
								<Option 
									key={`itemselectie-${index}`} 
									onClick={() => {
										setToggleArray(a => {
											const b = [...a]; b[index] = !b[index]; 
											return b;
										}); 
									}} 
									selected={toggleArray[index]}>
									<Hesje url={images[index]}/>
									<OptionLabel>{t(`dialogue-adc0-label-0${index + 1}`)}</OptionLabel>
								</Option>
							)}
						</OptionSubContainer>
					</div>
				</div>
			</StyledOptionsContainer>
			<div style={{margin: 'auto auto 0 auto'}}><BasicButton onClick={() => submitAnswer()}>Bevestig</BasicButton></div>		
		</Container>
	);
};

// styled components

const StyledOptionsContainer = styled(OptionsContainer)`
	position: relative;

	flex-grow: 1;
	height: 100%;
	width: 100%;

	// smaller screens

	@media (max-width: ${p => p.theme.responsive.media.lg}), (max-height: 800px) {
		margin-bottom: ${p => p.theme.responsive.padding.sm / 2}px;
		overflow-x: auto;
    }

	// bigger screens

	@media (min-width: ${p => p.theme.responsive.media.lg}) and (min-height: 800px){
		flex-wrap: wrap;
		margin-bottom: ${p => p.theme.responsive.padding.lg / 2}px;
    }

`;

const OptionSubContainer = styled.div`
	display: flex;
	flex-direction: row;
	/* height: 100%; */
	height: 100%;

	@media (max-width: ${p => p.theme.responsive.media.lg}), (max-height: 800px) {
		width: fit-content;
		gap: 5px;
    }

	// bigger screens
	@media (min-width: ${p => p.theme.responsive.media.lg}) and (min-height: 800px){
		flex-wrap: wrap;
		justify-content: space-between;
		gap: 10px;
		width: 100%;
    }
`;

const Container = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	/* outline: solid 4px pink; */
`;

const Option = styled(OptionButton)<{selected:boolean}>`

	position: relative;

	display: flex;
	flex-direction: column;


	@media (max-width: ${p => p.theme.responsive.media.lg}), (max-height: 800px) {
		height: 100%;
		width: 200px;
		/* flex-direction: row; */
		padding: ${p => p.theme.responsive.padding.sm / 3}px;
    }

	// bigger screens

	@media (min-width: ${p => p.theme.responsive.media.lg}) and (min-height: 800px){
		width: calc(25% - ${p => p.theme.responsive.padding.lg / 4}px);
		height: calc(50% - ${p => (p.theme.responsive.padding.lg / 4) / 2}px);
		padding: ${p => p.theme.responsive.padding.lg / 5}px;
		/* flex-grow: 1; */
    }

	
	${p => p.selected && css`
		outline:solid 2px ${p => p.theme.maincolors.GGBblue}!important;
		background-color: rgba(255,255,255,0.7);
	`}

`;

const Hesje = styled.div<{url: string | undefined}>`

	flex-grow: 1;
	min-height: 0;
	height: 100%;
	width: 100%;

    background-image: url(${p => p.url});
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;

`;

const OptionLabel = styled(Paragraph)`
	/* font-weight: bold; */
	margin: auto;
`;

export default ItemSelectie;
