import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	height: 100%;
	@media (max-width: ${p => p.theme.responsive.media.lg}){
		
		width:100%;
		padding: ${p => p.theme.responsive.padding.sm}px;
		> * + * {	
		margin-top: ${p => p.theme.responsive.padding.sm / 2}px;
		}  
	}
	@media (min-width: ${p => p.theme.responsive.media.lg}){
		
		width: 70%;
		margin: 0 15%;
		> * + * {	
			margin-top: ${p => p.theme.responsive.padding.lg / 2}px;
		}  
		padding-top: ${p => p.theme.responsive.padding.lg}px;
		padding-bottom: ${p => p.theme.responsive.padding.lg}px;

	}

`;

export default Container;