import { useGlobalContext } from '../../../context/global';
import { ConsoleLabel, ConsoleRow } from '.';

const stateList = [
	'none',
	'start scherm',
	'introductie scherm',
	'score scherm',
	'eindscherm',
	'game over scherm'
];

const ConsoleGameStateSelect = () => {

	const {gameState , setGameState} = useGlobalContext(); 
	
	return(
		<ConsoleRow>
			<ConsoleLabel>Dialogue Select</ConsoleLabel>
			<select onChange={(e) => setGameState && setGameState(parseInt(e.target.value))} value={gameState}>
				{stateList.map((state, index) => (
					<option key={index} label={state} value={index}/>))
				}
			</select>
		</ConsoleRow>
	);
};

export default ConsoleGameStateSelect;