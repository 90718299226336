import { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';

type TypographyProps = {
	className?: string;
	children: ReactNode;
	size?: number;
	style?: CSSProperties;
}

const Paragraph = (props : TypographyProps) => {
	return(
		<P style={props.style} size={props.size} className={props.className}>
			{props.children}
		</P>
	);
};

const Heading = (props : TypographyProps) => {
	return(
		<H style={props.style} size={props.size} className={props.className}>
			{props.children}
		</H>
	);
};

const ButtonText = (props : TypographyProps) => {
	return(
		<H style={{margin: '0', paddingTop: '0', lineHeight: '100%', position: 'relative', zIndex: '2'}}>
			{props.children}
		</H>
	);
};

const OptionText = styled(Paragraph) `
	display: flex;
	text-align: center;
	justify-content: center;
	font-family: 'Facit';

	padding: 0 1px;
`;



// styled components

const P = styled.p<{size: number}>`

${ p => !p.size ? css`

	font-family: 'Facit';
	box-sizing: border-box;

	margin: 0;
	@media (max-width: ${p => p.theme.responsive.media.lg}){
		font-size: 2vw;
    }
	@media (min-width: ${p => p.theme.responsive.media.lg}){
		font-size: 1.25vw;
    }

	` : p.size === 1 ? css`
		
	@media (max-width: ${p => p.theme.responsive.media.lg}){
		font-size: 1.5vw;
    }
	@media (min-width: ${p => p.theme.responsive.media.lg}){
		font-size: 1.25vw;
    }

	` : ''

}
`;

const H = styled.div<{size: number}>`

	font-family: 'Facit';
	font-weight: bold;

	${ p => !p.size ? css`

		@media (max-width: ${p => p.theme.responsive.media.lg}){
			font-size: 2.5vw;
			margin-bottom: 2.5vw;
		}
		@media (min-width: ${p => p.theme.responsive.media.lg}){
			font-size: 1.5vw;
			margin-bottom: 1.5vw;
		}

		` : ''
}
`;

export {Paragraph, Heading, ButtonText, OptionText};