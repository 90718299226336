import { ReactNode } from 'react';
import styled from 'styled-components';

const BasicFrame = (props: {children: ReactNode, className?: string, style?}) => {
	return (  
		<Frame style={props.style} className={props.className}>
			{props.children}	
		</Frame>
	);
};

// styled components
const Frame = styled.div`

	background: ${p => p.theme.maincolors.GGBblue};
	opacity: 0.9;
	color: white;
	position: relative;

	@media (max-width: ${p => p.theme.responsive.media.lg}){
		padding: ${p => p.theme.responsive.padding.sm / 1.5}px;

    }
	@media (min-width: ${p => p.theme.responsive.media.lg}){
		padding: ${p => p.theme.responsive.padding.lg / 2}px;
    }

	@media (min-width: ${p => p.theme.responsive.media.xl}){
		padding: ${p => p.theme.responsive.padding.xl / 1.5}px;
    }

	&:after{
		content:'';
		position: absolute;

		@media (max-width: ${p => p.theme.responsive.media.lg}){
			left: ${p => p.theme.responsive.padding.lg / 6}px;
			right: ${p => p.theme.responsive.padding.lg / 6}px; 
			bottom: ${p => p.theme.responsive.padding.lg / 6}px; 
			top: ${p => p.theme.responsive.padding.lg / 6}px; 
		}
		@media (min-width: ${p => p.theme.responsive.media.lg}){
			left: ${p => p.theme.responsive.padding.lg / 6}px;
			right: ${p => p.theme.responsive.padding.lg / 6}px; 
			bottom: ${p => p.theme.responsive.padding.lg / 6}px; 
			top: ${p => p.theme.responsive.padding.lg / 6}px; 
		}

		pointer-events: none;

		/* background: pink; */
		border: 1px solid rgba(255,255,255,0.2);
	}
`;
 
export default BasicFrame;