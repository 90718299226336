import { useGlobalContext } from '../../../context/global';
import { ConsoleLabel, ConsoleRow } from '.';
import { dialogueList } from '../../Dialogue/DialogueData';

const ConsoleDialogueSelect = () => {

	const { activeDialogue, setActiveDialogue} = useGlobalContext(); 
	
	return(
		<ConsoleRow>
			<ConsoleLabel>Dialogue Select</ConsoleLabel>
			<select onChange={(e) => setActiveDialogue && setActiveDialogue(e.target.value)} value={activeDialogue}>
				<option label={'none'} value={''}/>
				{dialogueList && Object.keys(dialogueList).map((key, index) => (
					<option key={`${index}-${key}-consolepentry`} label={dialogueList && dialogueList[key]?.chunk.key} value={dialogueList && dialogueList[key]?.chunk.key}/>))
				}
			</select>
		</ConsoleRow>
	);
};

export default ConsoleDialogueSelect;